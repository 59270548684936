import React from 'react';
import ContactUs from '../ContactUs/ContactUs';

const Contact = () => {
    return (
        <div className='p-6 md:p-20'>
            <ContactUs></ContactUs>
        </div>
    );
};

export default Contact;