import React, { useEffect, useState } from "react";
import { useLocation } from "../../../LocationContext";
import { BASE_URL } from "../../../api/api";
import TopDealsCard from "./TopDealsCard";


const TopDeals = () => {
  const [products, setProducts] = useState([]);

  const location = useLocation();

  console.log("top deals location data", location);

  useEffect(() => {
    fetch(`${BASE_URL}/top-deal`)
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);


  return (
    <div className="md:px-10 my-16">
      <h1 className="text-3xl font-bold text-left bg-gradient-to-r from-red-400 to-orange-500 text-transparent bg-clip-text">
        Top Deals
        <hr className="my-3 " />
      </h1>
      <div className="flex items-center">
        {/* <p className="text-indigo-500 ml-3 cursor-pointer">See All</p> */}
      </div>
      <div className="hidden md:grid md:grid-cols-4 md:gap-4 md:mt-1">
        {products.filter(product=> product.country.country_name===location.country).map((product) => (
          <TopDealsCard key={product.id} product={product} />
        ))}
      </div>
      <div className="md:hidden">
        <div className="carousel carousel-center max-w-md space-x-4">
          <div className="carousel-item max-h-full max-w-2xl">
            {products.filter(product=> product.country.country_name===location.country).map((product) => (
              <TopDealsCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopDeals;
