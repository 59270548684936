import React from "react";
import news from "../../Assets/jobs/news.jpg";
import BlogSection from "././../Blog/Blog";


const Press = () => {
    document.title = "Markdowntoday Newsroom";
    return(
        <div className="mx-auto">
            <div className="hero bg-[#ebaeae] dark:bg-black dark:text-white py-10 justify-start md:pl-20">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={news} className="md:max-w-md rounded-sm shadow-2xl mr-4" />
                    <div>
                    <h1 className="text-5xl font-bold">Newsroom</h1>
                    <p className="py-6">There’s always something happening here! Take a look at the latest in content, events, press and resources to learn more about what Markdowntoday has to offer.</p>
                    <strong className="text-lg">Press Contact: press@markdowntoday.com</strong>
                    </div>
                </div>
            </div>

            <div className="hero min-h-[20vh] bg-base-200">
                <div className="hero-content text-center">
                    <div className="max-w-[100%]">
                    <h1 className="text-4xl font-bold">Latest from the Blog</h1>
                    </div>
                </div>
            </div>
            <BlogSection></BlogSection>
        </div>
    )
}

export default Press;