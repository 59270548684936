import React, { useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "How do I request my Cash Back?",
      answer:
        "Visit the Payouts page of your account to select your preferred payout method and request a payment. You need to accumulate the minimum amount, specified on the Payments page, to be able to request your Cash Back payment. You will receive your payment via check, Direct Deposit to U.S. Bank Account, PayPal, Zelle, Venmo or <strong>eGift Card</strong>.",
    },
    {
      question: "When do I receive my Cash Back?",
      answer:
        "Earned Cash Back will remain as verified in your member account for between 30 to 90 days based on the return policy of the retailer as well as the store's payment cycle with Markdowntoday.com. We provide you with an estimated payment date to let you know approximately when most sales will become payable. Markdowntoday.com doesn't receive payment until this period has passed. Once we get paid, we can pay you!",
    },
    {
      question: "I am Missing Cash Back. What do I do?",
      answer:
        "Most stores will report your Cash Back earnings within 24 to 48 hours of a completed purchase. Some may take up to 7 days to report this. If after 7 days you do not see Cash Back in your account or the amount of Cash Back does not meet your expectations, please submit a Cash Back inquiry. All Cash Back inquiries must be submitted within 60 days of the completed transaction. Note that for most travel transactions, reported Cash Back may not become payable until up to 90 days after the reservation has been completed. Here is the link to submit a missing Cash Back inquiry.",
    },
    {
      question: "How does Markdowntoday.com Cash Back work?",
      answer:
        "<ul style='list-style-type: disc; padding-left: 1rem; text-align: left;'><li>To earn Cash Back, you must be logged into your Markdowntoday.com account.</li> <li>You can find retailers that are paying Cash Back and the amount of Cash Back they are paying on Markdowntoday.com.</li><li>Click on the 'Shop Now' button or on any Cash Back eligible coupon or deal and you will be taken to the retailer's website. Complete your purchase, and Cash Back will be added to your Markdowntoday.com account within 7 days.</li> <li>When you click on links from Markdowntoday.com's website, it sets a special cookie in your internet browser. This cookie indicates to the store that you came from Markdowntoday.com. This is how the store knows to report the sales and Cash Back you earned to your Markdowntoday.com account.</li></ul>",
    },
    {
      question: "How do I get my $10 Welcome Bonus?",
      answer:
        "Get your $10 Welcome Bonus when you shop and earn $10 in Cash Back. See full terms.",
    },
    {
      question: "Why and how does Markdowntoday.com pay me Cash Back?",
      answer:
        "Markdowntoday.com partners with over 2000 retailers to bring you the latest money-saving opportunities. These companies pay Markdowntoday.com a commission for bringing new sales to their websites. We share this commission with you in the form of Cash Back. The more people we bring to a retailer, the better Cash Back rates Markdowntoday.com can negotiate. It&#39;s a win-win!",
    },
    {
      question: "What is a Shopping Trip?",
      answer:
        "Anywhere on the Markdowntoday.com website or in a Markdowntoday.com email, phone app or browser add-on, all of your clicks on any Coupon, Deal or Shop Now button are recorded in the Shopping Trips table. A cash-back-eligible click will always have a Click ID. If you ever need to inquire about missing Cash Back, this number comes in handy when contacting Markdowntoday.com support. If the cash-back-eligible click results in a purchase, the store will notify Markdowntoday.com about your order within 7 days or less and a green money bag icon will appear next to your Shopping Trip, indicating that you have received Cash Back for your purchase.",
    },
    {
      question: "How can I ensure that my Cash Back gets tracked?",
      answer: `The easiest way to ensure a successful Markdowntoday.com Cash Back tracking is to install our Chrome
      browser add-on, Markdowntoday.com extension, and run its built-in tool that disables conflicting
      addons. These conflicting extensions take over Markdowntoday.com Cash Back cookies – the main
      cause for missing Cash Back.
      <ul style='list-style-type: disc; padding-left: 1rem; text-align: left;'>
      <li>Download our Chrome Markdowntoday.com extension.</li>
      <li>Disable the conflicting extensions in the Settings of the Markdowntoday.com extension. Click on the Markdowntoday.com extension (a pig icon at the top-right of the Chrome browser), select Settings within the Markdowntoday.com add-on dialog, and then click on the "Disable Conflicting Add-ons". Repeat this periodically, especially if you keep adding more add-ons, to make sure new add-ons are not taking over your Markdowntoday.com Cash Back cookies.</li>
    </ul>
    <p>Other ways to improve your Markdowntoday.com Cash Back tracking results:</p>
    <ol style='list-style-type: decimal; padding-left: 1rem; text-align: left;'>
      <li>When shopping via browser, such as Chrome, Firefox, Edge, etc., be sure to disable all AdBlocking and Shopping add-ons, including Coupons, Cash Back, Price-comparison, and donations extensions.</li>
      <li>If shopping on your iPhone/iPad in the Safari browser, make sure Safari does not auto-launch retailers' apps installed on your device. With the jump from Safari into an app, your Cash Back tracking cookie will be lost, and no Cash Back will be earned. To avoid the issue, simply delete the app.</li>
      <li>Verify that your browser is set to accept third-party cookies.</li>
      <li>Begin your shopping session at Markdowntoday.com with an empty shopping cart.</li>
      <li>Once you click through a Cash Back eligible link from Markdowntoday.com, complete your purchase on the retailer's website in a timely manner without browsing other pages.</li>
      <li>When applying coupons, remember to use only those offered by Markdowntoday.com. Using coupons from any other source might disqualify your purchase for Cash Back eligibility.</li>
      <li>Do not use gift cards or store credit cards to pay for your order.</li>
      <li>Contacting the merchant to modify your order via email, the merchant's website, online form, or phone will void Cash Back.</li>
    </ol>`,
    },
    {
      question: "When do I get paid?",
      answer:
        "Most Cash Back payments are sent on the next business day following payment request.",
    },
    {
      question: "How do I edit my email/password?",
      answer:
        "On the Profile page, you can update your password and email address, as well as any other information about yourself. If you have not previously done so, you will need to confirm your email address to access your Markdowntoday.com account.",
    },
    {
      question: "Changing Password",
      answer:
        "If you cannot login to your account, on the Login pop-up, click on Forgot Password? You will then receive an email with a link that will allow you to create a new password. If you have already logged in, find &#39;My Account&#39; at the top of the Markdowntoday.com site and, on the Profile page, click on &#39;Change Password.&#39;",
    },
    {
      question: "Changing Email",
      answer:
        "To change your current Markdowntoday.com email, log into your member account. On the Profile page,click on &#39;Change Email.&#39;",
    },
    {
      question: "What payout options are available?",
      answer:
      `<ul style='list-style-type: disc; padding-left: 1rem; text-align: left;'>
      <li>Check – Available in US only. Minimum withdrawal $25.</li>
      <li>Direct Deposit to Bank Account – Available in US only. Minimum withdrawal $0.01.</li>
      <li>PayPal – Minimum withdrawal $0.01 (Some older Markdowntoday.com accounts may need to accept the latest Markdowntoday.com Terms & Conditions to enable $0.01 minimum withdrawal. On the Payments page, directly below the Payment Method section, click on the 'Read & Accept Terms' link to accept the new terms. Only accounts that need to accept the latest terms will display this link).</li>
      <li>Venmo – Minimum withdrawal $0.01. US mobile phone number is required for Venmo. Markdowntoday.com will send your Cash Back payment to your Venmo account.</li>
      <li>Zelle – Minimum withdrawal $0.01. US mobile phone number is required for Zelle. Markdowntoday.com will send your Cash Back payment to your Zelle account with this number.</li>
      <li>eCards – All eCards are delivered electronically into your Markdowntoday.com account. Find them under My eCards at the top-right of the Payouts page.</li>
    </ul>
    <p>Your eCard will be available in your Markdowntoday.com account within 1 business day of your payout request. We'll notify you via email when it is ready to be redeemed.</p>
    <p>eCards can be used online and in-store. For online use, enter the card number on the checkout page. For in-store use, please add Visa® or American Express® cards to your phone's digital wallet and pay with your phone at the register. To use a retail eGift Card, print it out or show it on your mobile device to a cashier when checking out.</p>
    <p>Visa® and American Express® cards charge no fees, however, they expire 6 months after your payout is issued.</p>
    <p>Other eCards charge no fees and never expire.</p>`,
    },
    {
      question: "What is an affiliate program?",
      answer:
        "An affiliate program is an agreement in which a business pays another business or influencer ('the affiliate') a commission for sending traffic and/or sales their way. This can be achieved through web content, social media, or a product integration.",
    },
    {
      question: "Is it worth shopping through cashback sites?",
      answer:
        "Cashback sites offer a very attractive service by combining money-saving discount coupons with cashback offers. If one shops through the registered account, he/ she is entitled to receive a part of the payment as cashback. ",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="space-y-4">
      {faqs.map((faq, index) => (
        <div
          className="border border-gray-200 rounded-lg shadow-sm"
          key={index}
        >
          <button
            className="flex items-center justify-between w-full p-4 focus:outline-none"
            onClick={() => toggleAccordion(index)}
          >
            <span className="font-medium">{faq.question}</span>
            <HiOutlineChevronDown
              className={`transition-transform duration-300 ${
                activeIndex === index ? "transform rotate-180" : ""
              }`}
            />
          </button>
          {activeIndex === index && (
            <div className="px-4 py-2">
              <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQAccordion;
