import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../api/api";

const Tickets = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 4;
  const [ticketsData, setTicketsData] = useState([]);
  const [expandedTicket, setExpandedTicket] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(`${BASE_URL}/user-tickets`, config);
        setTicketsData(response.data);
      } catch (error) {
        // Handle the error
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, []); // Empty dependency array ensures the effect runs only once

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = ticketsData.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  const totalPages = Math.ceil(ticketsData.length / ticketsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleAccordion = (ticketId) => {
    if (expandedTicket === ticketId) {
      setExpandedTicket(null);
    } else {
      setExpandedTicket(ticketId);
    }
  };

  console.log("Tickets data:", ticketsData);

  return (
    <div className="min-h-screen p-4">
      <div className="max-w-3xl mx-auto w-full">
        <h1 className="text-3xl font-bold mb-4">My Tickets</h1>
        {currentTickets.length > 0 ? (
          <>
            <ul className="space-y-4">
              {currentTickets.map((ticket) => (
                <li
                  key={ticket.id}
                  className="bg-base-200 rounded-lg shadow p-4 text-left"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h2 className="text-lg font-bold">{ticket.subject}</h2>
                      <div className="flex">
                        <p className="text-gray-500">Status: {ticket.status} |</p>
                        <p className="text-gray-500 ml-1">
                          Created At:{" "}
                          {new Date(ticket.created_at).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <div className="ml-5">
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                        onClick={() => toggleAccordion(ticket.id)}
                      >
                        View
                      </button>
                    </div>
                  </div>
                  {expandedTicket === ticket.id && (
                    <div className="mt-4">
                      <p>{ticket.message}</p>
                      <div className="flex justify-end mt-2">
                        <button className="text-red-400 hover:text-red-600 py-1 px-2 rounded text-xs ">
                          Mark it closed
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  className={`${
                    i + 1 === currentPage
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-700"
                  } py-2 px-3 rounded mx-1`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="text-gray-700">No tickets found.</p>
        )}
      </div>
    </div>
  );
};

export default Tickets;
