import { createBrowserRouter } from "react-router-dom";
import Main from "../../Layout/Main";
import Home from "../../Pages/Home/Home/Home";
import Coupons from "../../Pages/Coupons/Coupons";
import CouponPage from "../../Pages/Coupons/CouponPage";
import Blog from "../../Pages/Blog/Blog";
import Shop from "../../Pages/Shop/Shop";
import Terms from "../../Pages/Shared/Footer/Terms";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import DashboardLayout from "../../Layout/DashboardLayout";
import Dashboard from "../../Pages/Dashboard/Dashboard/Dashboard";
import Appointment from "../../Pages/Dashboard/Appointment/Appointment";
import Referral from "../../Pages/Referral/Referral";
import HelpHome from "../../Pages/Shared/Help/HelpHome/HelpHome";
import Job from "../../Pages/Job/Job";
import Press from "../../Pages/Press/Press";
import About from "../../Pages/About/About";
import Apply from "../../Pages/Apply/Apply";
import PurchasedItems from "../../Pages/Dashboard/PurchasedItems/PurchasedItems";
import Affiliation from "../../Pages/Dashboard/Affiliation/Affiliation";
import Withdraw from "../../Pages/Dashboard/Withdraw/Withdraw";
import Tickets from "../../Pages/Dashboard/Ticket/Tickets";
import Disputes from "../../Pages/Dashboard/Disputes/Disputes";
import EditProfile from "../../Pages/Dashboard/EditProfile/EditProfile";
import ResetPassword from "../../Pages/Dashboard/ResetPassword/ResetPassword";
import PrivacyPolicy from "../../Pages/Shared/Footer/PrivacyPolicy";
import CookiePolicy from "../../Pages/Shared/Footer/CookiePolicy";
import ReferralPolicy from "../../Pages/Shared/Footer/ReferralPolicy";
import AdvertisementDisclosure from "../../Pages/Shared/Footer/AdvertisementDisclosure";
import CashBackLanding from "../../Pages/CashBackLanding/CashBackLanding";
import CategorizedCoupon from "../../Pages/Coupons/CategorizedCoupon";
import UserLogin from "../../Pages/Login/UserLogin";
import UserSignup from "../../Pages/Signup/UserSignup";
import ClaimCashback from "../../Pages/ClaimCashBack/ClaimCashBack";
import WhatsNew from "../../Pages/WhatsNew/WhatsNew";
import SingleBlogPost from "../../Pages/Blog/SingleBlogPost";
import FAQ from "../../Pages/Shared/Help/FAQ/FAQ";
import Contact from "../../Pages/Shared/Help/Contact/Contact";
import EmailPreferences from "../../Pages/EmailPreferences/EmailPreferences";
import TopDeals from "../../Pages/Home/TopDeals/TopDeals";
import SingleTopDeal from "../../Pages/Home/TopDeals/SingleTopDeal";
import CashBackLandingForDeals from "../../Pages/CashBackLanding/CashBackLandingForDeals";
import CategorizedProducts from "../../Pages/CategorizedProducts/CategorizedProducts";
import Merchant from "../../Pages/Merchant/Merchant";
import DetailsClaimCashBack from "../../Pages/ClaimCashBack/DetailsClaimCashBack";
import DetailsWhatsNew from "../../Pages/WhatsNew/DetailsWhatsNew";
import Coupon from "../../Pages/Coupons/Coupon";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Main></Main>,
      children: [
        {
          path: "/",
          element: <Home></Home>,
        },
        {
          path: "/coupons",
          element: <Coupons></Coupons>,
        },
        {
          path: "/coupon/:id",
          element: <Coupon></Coupon>,
        },
        {
          path: "/coupon-page",
          element: <CouponPage></CouponPage>,
        },
        {
          path: "/coupon-page/:name",
          element: <CategorizedCoupon></CategorizedCoupon>,
        },
        {
          path: "/products/:name",
          element: <CategorizedProducts></CategorizedProducts>,
        },
        {
          path: "/merchant/:name",
          element: <Merchant></Merchant>,
        },
        {
          path: "/blog",
          element: <Blog></Blog>,
        },
        {
          path: "/blog/:slug",
          element: <SingleBlogPost></SingleBlogPost>,
        },
        {
          path: "/login",
          element: <UserLogin></UserLogin>,
        },
        {
          path: "/signup",
          element: <UserSignup></UserSignup>,
        },
        {
          path: "/terms",
          element: <Terms></Terms>,
        },
        {
          path: "/top-deals",
          element: <TopDeals></TopDeals>,
        },
        {
          path: "/top-deals/:id",
          element: <SingleTopDeal></SingleTopDeal>,
        },
        {
          path: "/products/:id",
          element: <SingleTopDeal></SingleTopDeal>,
        },
        {
          path: "/shop",
          element: <Shop></Shop>,
        },
        {
          path: "/faq",
          element: <FAQ></FAQ>,
        },
        {
          path: "/referral",
          element: <Referral></Referral>,
        },
        {
          path: "/help",
          element: <HelpHome></HelpHome>,
        },
        {
          path: "/contact",
          element: <Contact></Contact>,
        },
        {
          path: "/email-preferences",
          element: <EmailPreferences></EmailPreferences>,
        },
        {
          path: "/job",
          element: <Job></Job>,
        },
        {
          path: "/press",
          element: <Press></Press>,
        },
        {
          path: "/about",
          element: <About></About>,
        },
        {
          path: "/apply",
          element: <Apply></Apply>,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy></PrivacyPolicy>,
        },
        {
          path: "/cookie-policy",
          element: <CookiePolicy></CookiePolicy>,
        },
        {
          path: "/referral-policy",
          element: <ReferralPolicy></ReferralPolicy>,
        },
        {
          path: "/advertisement-disclosure",
          element: <AdvertisementDisclosure></AdvertisementDisclosure>,
        },
        {
          path: "/cash-back-landing",
          element: <CashBackLanding></CashBackLanding>,
        },
        {
          path: "/cash-back-landing/:name",
          element: <PrivateRoute><CashBackLanding></CashBackLanding></PrivateRoute>,
        },
        {
          path: "/cash-back-landing-products/:id",
          element: <PrivateRoute><CashBackLandingForDeals></CashBackLandingForDeals></PrivateRoute>,
        },
        {
          path: "/claim-cash-back",
          element: <ClaimCashback></ClaimCashback>,
        },
        {
          path: "/claim-cash-back/:id",
          element: <DetailsClaimCashBack></DetailsClaimCashBack>,
        },
        {
          path: "/whatsnew",
          element: <WhatsNew></WhatsNew>,
        },
        {
          path: "/whatsnew/:id",
          element: <DetailsWhatsNew></DetailsWhatsNew>,
        },

      ],
    },
    {
      path: '/dashboard',
      element: <PrivateRoute><DashboardLayout></DashboardLayout></PrivateRoute>,
      children: [
          {
              path: '/dashboard',
              element: <Dashboard></Dashboard>
          },
          {
              path: '/dashboard/appointment',
              element: <Appointment></Appointment>
          },
          {
              path: '/dashboard/purchased-items',
              element: <PurchasedItems></PurchasedItems>
          },
          {
              path: '/dashboard/affiliation',
              element: <Affiliation></Affiliation>
          },
          {
              path: '/dashboard/withdraw',
              element: <Withdraw></Withdraw>
          },
          {
              path: '/dashboard/tickets',
              element: <Tickets></Tickets>
          },
          {
              path: '/dashboard/disputes',
              element: <Disputes></Disputes>
          },
          {
              path: '/dashboard/edit-profile',
              element: <EditProfile></EditProfile>
          },
          {
              path: '/dashboard/reset-password',
              element: <ResetPassword></ResetPassword>
          },
      ]
    }
   
  ]);
  
  export default router;