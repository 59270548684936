// import React, { useEffect } from "react";
// import { isMobileOnly } from "react-device-detect";

// const UserTracking = () => {
//   useEffect(() => {
//     fetch("/api/json/") // Updated URL
//       .then((response) => response.json())
//       .then((data) => console.log("Country:", data.country_name))
//       .catch((error) => console.error("Error:", error));
//   }, []);

//   return (
//     <div>
//       {/* {isMobileOnly ? (
//         <p>Viewing on a mobile device</p>
//       ) : (
//         <p>Viewing on a desktop device</p>
//       )} */}
//     </div>
//   );
// };

// export default UserTracking;
import React from 'react';

const UserTracking = () => {
  return (
    <div>
      
    </div>
  );
};

export default UserTracking;