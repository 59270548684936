import React, { useEffect, useState } from 'react';
import { BASE_URL, IMG_BASE_URL } from '../../../api/api';
import { Link } from 'react-router-dom';

const BlogPreview3 = () => {
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/large-banner`)
      .then((response) => response.json())
      .then((data) => setBannerData(data));
  }, []);

  if (bannerData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative">
      <div className="absolute inset-0">
        {bannerData.map((banner, index) => (
          <img
            key={index}
            src='/Login-bg.jpg'
            alt="Background"
            className="object-cover w-full h-full"
          />
        ))}
      </div>
      <div className="relative bg-opacity-90 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white">
        <div className="container mx-auto px-4 py-8">
          {bannerData.map((banner, index) => (
            <div key={index} className="flex flex-col-reverse md:flex-row items-start my-8 gap-3">
              <div className="md:w-1/2 md:mt-0 mt-4">
                <img
                  src={`${IMG_BASE_URL}/banners/${banner.photo}`}
                  alt="Banner"
                  className="object-cover w-full h-auto md:max-h-96 rounded-lg"
                />
              </div>
              <div className="md:w-1/2 md:pl-8">
                <h1 className="text-4xl font-bold mb-4">{banner.title}</h1>
                <p className="text-xl  mb-4">{banner.description}</p>
                <span className="bg-[#E94036] shadow-md rounded-md px-5 py-3 text-sm text-white mr-2">
                <Link to="/login" className='mb-2'>Get Started</Link>
              </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPreview3;
