import React from 'react';

const PayoutHistory = () => {
  return (
    <div className="bg-base-200 rounded shadow p-4">
      <h2 className="text-2xl font-bold mb-4 text-left">Payout History</h2>

      <div className="mb-4 text-left">
        <h3 className="text-lg font-bold mb-2 text-left">Step 1: Access Payout History</h3>
        <p className="text-gray-600">
          To view your payout history, follow these steps:
        </p>
        <ol className="list-decimal ml-6 mt-2 text-left">
          <li>Log in to your account.</li>
          <li>Navigate to the "Payout History" section.</li>
        </ol>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2 text-left">Step 2: Review Payout Details</h3>
        <p className="text-gray-600">
          Once you're on the payout history page, you'll find the following information:
        </p>
        <ul className="list-disc ml-6 mt-2 text-left">
          <li>Date: The date when the payout was processed.</li>
          <li>Amount: The amount paid out to you.</li>
          {/* Add more details as needed */}
        </ul>
      </div>

      <div>
        <h3 className="text-lg font-bold mb-2 text-left">Step 3: Additional Actions</h3>
        <p className="text-gray-600">
          You may have the following options available on the payout history page:
        </p>
        <ul className="list-disc ml-6 mt-2 text-left">
          <li>Download: Download a detailed report of your payout history.</li>
          <li>Filter: Apply filters to view payouts within a specific date range or by a specific criteria.</li>
          {/* Add more options as needed */}
        </ul>
      </div>
    </div>
  );
};

export default PayoutHistory;
