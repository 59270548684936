import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cashbackData from "../../../src/api/cashbackApi";

const DetailsClaimCashBack = () => {
  const { id } = useParams();
  const post = cashbackData.find((post) => post.id.toString() === id);

  if (!post) {
    return <div>Post not found</div>;
  }


  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-6xl mx-auto bg-base-200 rounded-lg shadow-lg p-6">
        <img
          className="rounded-lg mb-4"
          src={post.image}
          alt="Blog post"
        />
        <h2 className="text-3xl font-bold mb-2">{post.title}</h2>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
    </div>
  );
};

export default DetailsClaimCashBack;

