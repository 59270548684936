import React, { useState } from 'react';

const PurchasedItems = () => {
  const items = [
    { id: 1, name: 'Item 1', price: 10, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { id: 2, name: 'Item 2', price: 20, description: 'Pellentesque ac felis tellus. Sed eleifend fringilla lectus.' },
    { id: 3, name: 'Item 3', price: 30, description: 'Suspendisse potenti. Nulla nec enim id arcu dignissim..' },
    { id: 4, name: 'Item 4', price: 40, description: 'Vestibulum lobortis metus eu metus pretium convallis.' },
    { id: 5, name: 'Item 5', price: 50, description: 'Integer in fringilla felis. Donec varius tincidunt lacus.' },
    { id: 6, name: 'Item 6', price: 60, description: 'Aenean feugiat consectetur sem, id pretium nisl sollicitudin a.' },
    { id: 7, name: 'Item 7', price: 70, description: 'Phasellus euismod bibendum iaculis. Duis tincidunt erat ac.' },
    { id: 8, name: 'Item 8', price: 80, description: 'Quisque semper vulputate facilisis. Vivamus ut ultrices nunc.' },
    { id: 9, name: 'Item 9', price: 90, description: 'Fusce posuere aliquet ante id rhoncus. Etiam vel mauris nec neque.' },
    { id: 10, name: 'Item 10', price: 100, description: 'Curabitur ullamcorper turpis auctor, fermentum orci in, suscipit.' },
    { id: 11, name: 'Item 11', price: 110, description: 'Aliquam sed ex id urna mattis eleifend. Suspendisse vel tortor.' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="min-h-screen p-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Purchased Items</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {currentItems.map(item => (
            <div key={item.id} className="bg-base-200 rounded-lg shadow p-4">
              <h2 className="text-lg font-bold mb-2">{item.name}</h2>
              <p className="text-gray-600">${item.price}</p>
              <p className="text-sm text-gray-500 mt-2">{item.description}</p>
              <button className="mt-4 bg-[#ED3A58] hover:bg-red-700 text-white px-4 py-2 rounded outline-none focus:outline-none">
                Add to Cart
              </button>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <nav>
            <ul className="flex items-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`cursor-pointer px-3 py-1 rounded-lg mx-1 ${
                    index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-300'
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PurchasedItems;
