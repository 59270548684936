import React, { useState } from "react";

const ContactUs = () => {
  const [selectedForm, setSelectedForm] = useState("Customer Support");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission goes here
  };

  return (
    <div className="bg-base-200 rounded shadow p-4">
      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>

      <p className="text-gray-600 mb-4">
        We're here to help! If you have any questions, feedback, or need
        assistance, please feel free to reach out to us using the contact forms
        below:
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Options */}
        <div>
        <div>
          <p className="text-lg font-bold mb-2">Select a contact form:</p>
          <p className="text-gray-600 mb-4">
            Please choose one of the following options to get in touch with us. We value your feedback, questions,
            and inquiries, and our team is ready to assist you. Whether you need help with our products, have a
            potential partnership opportunity, media inquiries, or wish to explore job opportunities with us, we're
            here for you. Select the most relevant form, and we'll get back to you as soon as possible.
          </p>
        </div>
          <div className="flex flex-col space-y-2">
            <label className="flex items-center">
              <input
                type="radio"
                value="Customer Support"
                checked={selectedForm === 'Customer Support'}
                onChange={() => setSelectedForm('Customer Support')}
                className="mr-2"
              />
              Customer Support
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="Partnerships"
                checked={selectedForm === 'Partnerships'}
                onChange={() => setSelectedForm('Partnerships')}
                className="mr-2"
              />
              Partnerships
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="Media Inquiries"
                checked={selectedForm === 'Media Inquiries'}
                onChange={() => setSelectedForm('Media Inquiries')}
                className="mr-2"
              />
              Media Inquiries
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="Job Opportunities"
                checked={selectedForm === 'Job Opportunities'}
                onChange={() => setSelectedForm('Job Opportunities')}
                className="mr-2"
              />
              Job Opportunities
            </label>
          </div>
        </div>

        {/* Forms */}
        <div>
          {selectedForm === "Customer Support" && (
            <div className="bg-base-100 p-5">
              <h3 className="text-lg font-bold mb-2">Customer Support</h3>
              <p className="text-gray-600">
                For general inquiries or assistance, contact customer support
                team:
              </p>
              <form onSubmit={handleSubmit} className="mt-2">
                <div className="flex flex-col space-y-2">
                  <input
                    type="text"
                    placeholder="Name"
                    className="border p-2 rounded"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="border p-2 rounded"
                  />
                  <textarea
                    placeholder="Message"
                    className="border p-2 rounded"
                    rows="4"
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
          {selectedForm === "Partnerships" && (
            <div className="bg-base-100 p-5">
              <h3 className="text-lg font-bold mb-2">Partnerships</h3>
              <p className="text-gray-600">
                Interested in partnering with us? Reach out to our partnerships
                team:
              </p>
              <form onSubmit={handleSubmit} className="mt-2">
                <div className="flex flex-col space-y-2">
                  <input
                    type="text"
                    placeholder="Name"
                    className="border p-2 rounded"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="border p-2 rounded"
                  />
                  <textarea
                    placeholder="Message"
                    className="border p-2 rounded"
                    rows="4"
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
          {selectedForm === "Media Inquiries" && (
            <div className="bg-base-100 p-5">
              <h3 className="text-lg font-bold mb-2">Media Inquiries</h3>
              <p className="text-gray-600">
                For media inquiries or press-related matters, please contact:
              </p>
              <form onSubmit={handleSubmit} className="mt-2">
                <div className="flex flex-col space-y-2">
                  <input
                    type="text"
                    placeholder="Name"
                    className="border p-2 rounded"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="border p-2 rounded"
                  />
                  <textarea
                    placeholder="Message"
                    className="border p-2 rounded"
                    rows="4"
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
          {selectedForm === "Job Opportunities" && (
            <div className="bg-base-100 p-5">
              <h3 className="text-lg font-bold mb-2">Job Opportunities</h3>
              <p className="text-gray-600">
                Interested in joining our team? Submit your job inquiries here:
              </p>
              <form onSubmit={handleSubmit} className="mt-2">
                <div className="flex flex-col space-y-2">
                  <input
                    type="text"
                    placeholder="Name"
                    className="border p-2 rounded"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="border p-2 rounded"
                  />
                  <textarea
                    placeholder="Message"
                    className="border p-2 rounded"
                    rows="4"
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
