import React, { useContext, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { BASE_URL } from "../../api/api";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { signIn, googleSignIn } = useContext(AuthContext);
  const [loginError, setLoginError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/";

  const handleLogin = (data) => {
    signIn(data.email, data.password)
      .then((result) => {
        const user = result.user;
        console.log("Successfully Logged in!");

        const response = axios
          .post(`${BASE_URL}/user-login`, {
            email: data.email,
            password: data.password,
            scope: "",
          })
          .then((res) => {
            const apiToken = res.data.user.access_token;
            localStorage.setItem("access_token", apiToken);
            const accessToken = localStorage.getItem("access_token");
            const config = {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            };
          })
          .catch(function (error) {
            // Handle any errors that occur during the request
            console.error(error);
          });

        // Reload the page after 2 seconds
        setTimeout(() => {
          navigate(from, { replace: true });
          window.location.reload();
        }, 2000);

        setLoginSuccess(true);
      })
      .catch((error) => {
        console.log(error.message);
        setLoginError(error.message);
      });

    console.log("Access Token: ", localStorage.getItem("access_token"));
  };

  const handleGoogleSignIn = () => {
    googleSignIn()
      .then((result) => {
        const user = result.user;
        console.log(user);
        window.location.reload(); // Reload the page
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  return (
    <div>
      {loginError && <p style={{ color: "red" }}>Error: {loginError}</p>}
      {loginSuccess && <p style={{ color: "green" }}>Successfully Logged in!</p>}
      <form onSubmit={handleSubmit(handleLogin)}>
        <p>Email</p>
        <input
          type="email"
          {...register("email", { required: "Email is required!" })}
          className="bg-gray-50 mb-2 rounded shadow-inner placeholder:text-center h-10   focus:outline-blue-800 border-[1px] border-gray-800 w-full"
        />
        {errors.email && <p style={{ color: "red" }}>{errors.email.message}</p>}
        <p>Password</p>
        <input
          type="password"
          {...register("password", {
            required: "Password is required!",
            minLength: {
              value: 6,
              message: "Password must be 6 characters or longer",
            },
          })}
          className="bg-gray-50 rounded shadow-inner placeholder:text-center h-10  focus:outline-blue-800 border-[1px] border-gray-800 w-full"
        />
        {errors.password && <p style={{ color: "red" }}>{errors.password.message}</p>}

        <button
          type="submit"
          className="h-10 w-full loginButton mt-2 rounded text-white text-center"
        >
          Login
        </button>
      </form>
      <Link onClick={handleGoogleSignIn}>
        <div className="h-10 w-full px-2  flex justify-start pl-14 gap-4 items-center bg-[#E3F1FE]   mt-2 rounded  border-[1px] border-[#207198] cursor-pointer shadow-sm hover:shadow-lg">
          <FcGoogle className="text-lg"></FcGoogle>
          <h2 className="text-sm text-[#207198]">Continue With Google</h2>
        </div>
      </Link>
      <Link to="/userProfile">
        <div className="h-10 w-full px-2 flex justify-start gap-4 pl-14 items-center bg-[#E3F1FE] mt-4 rounded  border-[1px] border-[#207198] cursor-pointer shadow-sm hover:shadow-lg">
          <BsFacebook className="text-lg"></BsFacebook>
          <h2 className="text-sm text-[#207198]">Continue With Facebook</h2>
        </div>
      </Link>
    </div>
  );
};

export default Login;
