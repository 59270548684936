import React, { useState, useEffect } from 'react';
import { BASE_URL, IMG_BASE_URL } from '../../../../api/api';
import { Link } from 'react-router-dom';

const TopEarningRef = () => {

  const [stores, setStores] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/top-earning-referral`)
      .then(response => response.json())
      .then(data => {
        // Get the first 5 items from the API response
        const topStores = data.slice(0, 5);
        setStores(topStores);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="container mx-auto mt-6">
      <h1 className="text-xl font-bold mb-4">Top Earning Referral</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 max-w-5xl">
        {stores.map(store => (
            <Link to={`/cash-back-landing/${store.name}`} target="_blank" key={store.id}>
            <div key={store.id} className="bg-base-200 rounded shadow p-4">
            <img
              src={`${IMG_BASE_URL}/partner/${store.photo}`}
              alt={store.name}
              className="w-30 h-30 object-cover rounded"
            />
            <h2 className="text-lg font-bold">{store.amount}% Cash Back</h2>
            <p className="text-gray-600">{store.name}</p>
          </div>
            </Link>
          
        ))}
      </div>
    </div>
  );
};

export default TopEarningRef;
