import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../../api/api";

const NavbarBelow = () => {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/allcategories`)
      .then((response) => response.json())
      .then((data) => setCoupons(data))
      .catch((error) => console.log(error));
  }, []);

  const splitArrayIntoTwoColumns = (arr) => {
    const halfLength = Math.ceil(arr.length / 2);
    const firstColumn = arr.slice(0, halfLength);
    const secondColumn = arr.slice(halfLength);
    return [firstColumn, secondColumn];
  };

  const [firstColumnItems, secondColumnItems] =
    splitArrayIntoTwoColumns(coupons);

  return (
    <div
      className="bg-blue-200 flex items-center justify-between md:px-[103px] px-4 py-2"
      style={{ fontFamily: "Roboto, sans-serif" }}
    >
      <div className="navbar-center flex">
        <div className="dropdown dropdown-hover">
          <label
            tabIndex={0}
            className="text-sky-800 font-bold md:text-lg text-xs  flex items-center text-center justify-center"
          >
            Stores{" "}
            <FontAwesomeIcon icon={faCaretDown} className="w-4 h-4 ml-1" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-0 shadow bg-base-100 w-[315px]"
          >
            <div className="flex justify-center">
              <div>
                {firstColumnItems.map((category) => (
                  <li className="p-0 rounded-none" key={category.id}>
                    <Link
                      className="rounded-none"
                      to={`/products/${category.name}`}
                    >
                      {category.name}
                    </Link>

                    {category?.subs?.length ? (
                      <ul>
                        {category.subs.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link
                                className="rounded-none"
                                to={`/products/${item.name}`}
                              >
                                {item.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </div>
              <div>
                {secondColumnItems.map((category) => (
                  <li key={category.id}>
                    <Link
                      className="rounded-none"
                      to={`/products/${category.name}`}
                    >
                      {category.name}
                    </Link>

                    {category?.subs?.length ? (
                      <ul>
                        {category.subs.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link
                                className="rounded-none"
                                to={`/products/${item.name}`}
                              >
                                {item.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div className="navbar-center flex">
        <div className="dropdown dropdown-hover">
          <label
            tabIndex={0}
            className="text-sky-700 font-bold md:text-lg text-xs  flex items-center text-center justify-center"
          >
            <Link to="/coupon-page">Coupons </Link>
            <FontAwesomeIcon icon={faCaretDown} className="w-4 h-4 ml-1" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-0 shadow bg-base-100 w-[300px]"
          >
            <div className="flex justify-center">
              <div>
                {firstColumnItems.map((category) => (
                  <li key={category.id}>
                    <Link
                      className="rounded-none"
                      to={`/coupon-page/${category.name}`}
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </div>
              <div>
                {secondColumnItems.map((category) => (
                  <li key={category.id}>
                    <Link
                      className="rounded-none"
                      to={`/coupon-page/${category.name}`}
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div className="navbar-center flex text-sky-700 font-bold md:text-lg text-xs ">
        <Link to="/claim-cash-back">Bonus Cash Back</Link>
      </div>
      <div className="navbar-center flex text-sky-700 font-bold md:text-lg text-xs ">
        <Link to="/help">Help</Link>
      </div>
    </div>
  );
};

export default NavbarBelow;
