import React, { useState } from 'react';

const EmailPreferences = () => {
  const [emailSubscription, setEmailSubscription] = useState(true);
  const [promotionSubscription, setPromotionSubscription] = useState(true);
  const [newsletterSubscription, setNewsletterSubscription] = useState(true);

  const handleEmailSubscriptionChange = () => {
    setEmailSubscription(!emailSubscription);
  };

  const handlePromotionSubscriptionChange = () => {
    setPromotionSubscription(!promotionSubscription);
  };

  const handleNewsletterSubscriptionChange = () => {
    setNewsletterSubscription(!newsletterSubscription);
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Email Preferences</h1>
      <div className="bg-base-200 rounded-lg shadow-md p-6">
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 rounded"
            checked={emailSubscription}
            onChange={handleEmailSubscriptionChange}
          />
          <label className="ml-2 text-sm ">
            Receive general emails
          </label>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 rounded"
            checked={promotionSubscription}
            onChange={handlePromotionSubscriptionChange}
          />
          <label className="ml-2 text-sm ">
            Receive promotional offers
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 rounded"
            checked={newsletterSubscription}
            onChange={handleNewsletterSubscriptionChange}
          />
          <label className="ml-2 text-sm ">
            Receive newsletters
          </label>
        </div>
      </div>
    </div>
  );
};

export default EmailPreferences;
