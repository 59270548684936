import { BottomNavigation } from "reactjs-bottom-navigation";
import { AiFillHome } from "react-icons/ai";
import { HiArchive } from "react-icons/hi";
import { HiMail } from "react-icons/hi";
import { RiCoupon3Fill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi"; // Import HiUserGroup icon from react-icons/hi
import React from "react";
import { useNavigate } from "react-router-dom";
import "./bottomnav.css";

const BottomNav = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Function to scroll to the top of the page
  };

  const bottomNavItems = [
    {
      title: "Home",
      icon: <AiFillHome className="nav-icon" />,
      activeIcon: <AiFillHome className="nav-icon" />,
      onClick: () => {
        navigate("/");
        scrollToTop(); // Scroll to top after navigating to the Home route
      },
    },
    {
      title: "Feeds",
      icon: <HiMail className="nav-icon" />,
      activeIcon: <HiMail className="nav-icon" />,
      onClick: () => {
        navigate("/blog");
        scrollToTop(); // Scroll to top after navigating to the Feeds route
      },
    },
    {
      title: "Coupon",
      icon: <RiCoupon3Fill className="nav-icon" />,
      activeIcon: <RiCoupon3Fill className="nav-icon" />,
      onClick: () => {
        navigate("/coupons");
        scrollToTop(); // Scroll to top after navigating to the Coupon route
      },
    },
    {
      title: "Account",
      icon: <HiUserGroup className="nav-icon" />, // Use HiUserGroup icon for the Account title
      activeIcon: <HiUserGroup className="nav-icon" />, // Use HiUserGroup icon for the active state of Account
      onClick: () => {
        navigate("/dashboard");
        scrollToTop(); // Scroll to top after navigating to the Account route
      },
    },
    {
      title: "About",
      icon: <HiArchive className="nav-icon" />,
      activeIcon: <HiArchive className="nav-icon" />,
      onClick: () => {
        navigate("/about");
        scrollToTop(); // Scroll to top after navigating to the About route
      },
    },
  ];

  return (
    <div className="bottom-nav">
      <BottomNavigation
        items={bottomNavItems}
        defaultSelected={0}
        onItemClick={(item) => console.log(item)}
      />
    </div>
  );
};

export default BottomNav;
