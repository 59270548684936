import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/api";
import {
  RiFacebookLine,
  RiTwitterLine,
  RiShareLine,
  RiWhatsappLine,
  RiPinterestLine,
} from "react-icons/ri";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
} from "react-share";
import { useSpring, animated } from "react-spring"; 
import NoCouponsMessage from "../Coupons/NoCouponsMessage";
import { Link } from "react-router-dom";

const MerchantwiseCoupons = ({ name }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [couponData, setCouponData] = useState([]);
  const [copiedCouponCode, setCopiedCouponCode] = useState("");

  // Number of items to display per page
  const itemsPerPage = 3;

  useEffect(() => {
    // Fetch coupon data from the API
    axios
      .get(`${BASE_URL}/partner-coupons/${name}`)
      .then((response) => {
        const data = response.data;
        setCouponData(data);
      })
      .catch((error) => {
        console.error("Error fetching coupon data:", error);
      });
  }, [name]);

  // Calculate the index of the first and last item to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Slice the coupon data array to get the items for the current page
  const currentCoupons = couponData.slice(firstIndex, lastIndex);

  // Function to handle page navigation
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to copy the coupon code to the clipboard
  const copyCouponCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopiedCouponCode(code);
  };

  // Function to get the formatted coupon price based on the coupon type
  const getCouponPrice = (coupon) => {
    if (coupon.type === 0) {
      return `X${coupon.price}`;
    } else if (coupon.type === 1) {
      return `Y${coupon.price}`;
    } else {
      return coupon.price;
    }
  };

  const shareOnFacebook = (coupon) => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      coupon.coupon_link
    )}&quote=${encodeURIComponent(
      coupon.coupon_title + " - " + coupon.description
    )}`;
    window.open(facebookShareUrl, "_blank", "width=600,height=400");
  };

  const [showShareButtons, setShowShareButtons] = useState(false);
  const [showMainShareButton, setShowMainShareButton] = useState(true);

  // Function to toggle share buttons visibility
  const toggleShareButtons = () => {
    setShowShareButtons(!showShareButtons);
     // Hide the main share button after the share buttons appear
  };

  // useEffect to show the main share button when share buttons are hidden
  useEffect(() => {
    if (!showShareButtons) {
      setShowMainShareButton(true);
    }
  }, [showShareButtons]);

  const shareButtonsAnimation = useSpring({
    opacity: showShareButtons ? 1 : 0,
    maxHeight: showShareButtons ? "100px" : "0",
    overflow: "hidden",
  });

  return (
    <>
    {couponData.length === 0 ? (
      // Render NoCouponMessage component when couponData is empty
      <NoCouponsMessage />
    ) : (
      <>
      {currentCoupons.map((coupon) => (
        <div
          key={coupon.id}
          className="bg-white rounded-lg shadow-md overflow-hidden mt-8 mb-8 mx-10"
        >
          <div className="bg-gradient-to-r from-red-500 to-orange-500 px-4 py-3 flex items-center justify-between">
            <div className="text-white font-bold text-base sm:text-lg">
              {coupon.type === 0 ? "P" : coupon.type === 1 ? "D" : ""}
            </div>
            <div className="text-white text-xs sm:text-sm">
              Expires: {coupon.end_date}
            </div>
            <div className="text-white text-xs sm:text-sm">
              Started From: {coupon.start_date}
            </div>

            <div
              className="flex items-center space-x-2"
              style={{
                transition: "opacity 0.3s ease-in-out", // CSS transition for opacity
                opacity: showMainShareButton ? 1 : 0, // Show main share button if true, otherwise hide it
              }}
            >
              <button
              className="text-white hover:text-gray-200 p-2 rounded-full"
              style={{
                backgroundColor: showShareButtons ? "#FF0000" : "#1091F3",
                // Use a different color for the main button when share buttons are shown
              }}
              onClick={toggleShareButtons}
            >
              {showShareButtons ? (
                // Cross icon for collapsing the share buttons
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                // Share icon for displaying the share buttons
                <RiShareLine size={16} />
              )}
            </button>
            </div>

            {/* Share Buttons */}
            {showShareButtons && (
              <animated.div
            className="flex items-center space-x-2"
            style={shareButtonsAnimation} // Apply the animation style using react-spring's useSpring hook
          >
                {/* Facebook */}
                <FacebookShareButton
                  url={coupon.aff_link}
                  quote={coupon.coupon_title + " - " + coupon.description}
                  hashtag="#YourHashtag"
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#1091F3" }}
                  >
                    <RiFacebookLine size={17} />
                  </button>
                </FacebookShareButton>

                {/* Twitter */}
                <TwitterShareButton
                  url={coupon.aff_link}
                  title={coupon.coupon_title}
                  hashtags={["YourHashtag1", "YourHashtag2"]} // Replace with your hashtags
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#1da1f2" }}
                  >
                    <RiTwitterLine size={16} /> {/* Reduced icon size */}
                  </button>
                </TwitterShareButton>

                {/* Whatsapp */}
                <WhatsappShareButton
                  url={coupon.aff_link}
                  title={coupon.coupon_title}
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#25d366" }}
                  >
                    <RiWhatsappLine size={16} /> {/* Reduced icon size */}
                  </button>
                </WhatsappShareButton>

                {/* Pinterest */}
                <PinterestShareButton
                  url={coupon.aff_link}
                  media={coupon.image_url} // Replace with the coupon image URL
                  description={coupon.coupon_title}
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#bd081c" }}
                  >
                    <RiPinterestLine size={16} /> {/* Reduced icon size */}
                  </button>
                </PinterestShareButton>
              </animated.div>
            )}
            {/* End Share Buttons */}
          </div>
          <div className="p-4 flex flex-col sm:flex-row justify-between">
            <div className="flex items-center mb-4">
              <img
                src={coupon.image_url}
                alt="Logo"
                className="h-8 sm:h-12 mr-4 sm:mr-7"
              />
              <div>
                <div className="text-sm sm:text-base font-bold mb-2">
                  {coupon.coupon_title}
                </div>
                <div className="text-gray-600 max-w-2xl text-xs sm:text-sm">
                  {coupon.description}
                </div>
              </div>
            </div>
            <button
              className="bg-red-500 hover:bg-orange-600 text-white btn rounded-lg shadow"
              onClick={() => copyCouponCode(coupon.code)}
            >
              {copiedCouponCode === coupon.code ? "Copied!" : "Get Coupon"}
            </button>
          </div>
          <div className="bg-gray-200 px-4 py-3 flex items-center justify-between text-green-600 font-semibold text-xs sm:text-sm">
            <div>
              Plus{" "}
              {coupon.type === "0"
                ? `${coupon.price}%`
                : coupon.type === "1"
                ? `$${coupon.price}`
                : ""}{" "}
              Cash Back With Markdowntoday
            </div>
            <div>
            <Link to="/referral" className="text-indigo-500 hover:text-indigo-600">
                Refer & Earn
              </Link>
            </div>
          </div>
        </div>
      ))}

        {/* Pagination */}
        {couponData.length > itemsPerPage && (
          <div className="flex justify-center mt-8">
            <button
              className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-l text-xs sm:text-base"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <button
              className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-r ml-1 text-xs sm:text-base"
              disabled={currentCoupons.length < itemsPerPage}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        )}
      </>
    )}
  </>
  );
};

export default MerchantwiseCoupons;
