import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoonIcon from "@heroicons/react/24/outline/MoonIcon";
import SunIcon from "@heroicons/react/24/outline/SunIcon";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { animated, useSpring } from 'react-spring';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { themeChange } from "theme-change";
import logo from "../../../Assets/logo.svg";
import dummyImage from "../../../Assets/profile.png";
import { useLocation } from "../../../LocationContext";
import { BASE_URL, IMG_BASE_URL } from "../../../api/api";
import { AuthContext } from "../../../contexts/AuthProvider";
import "../../Home/HomeCSS/index.css";
import Login from "../../Login/Login";
import Signup from "../../Signup/Signup";
import SearchBar from "../SearchBar/SearchBar";
import BottomNav from "./BottomNav/BottomNav";

import {
  MdArticle,
  MdAttachMoney,
  MdFormatListBulleted,
  MdHome,
  MdLocationOn,
  MdNewReleases,
} from "react-icons/md";

const Navber = () => {
  const truncateName = (name) => {
    if (name && name.length > 10) {
      const words = name.split(" ");
      return words[0];
    }
    return name;
  };

  const [person, setPerson] = useState([]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        console.log("Web er jonno Access token from Navbar:", accessToken);
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        console.log("Eita config:", config);
        const response = await axios.get(`${BASE_URL}/profile`, config);
        setPerson(response.data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);

  const location = useLocation();

  console.log("Location based country code:", location.countryCode);

  const [showLogin, setShowLogin] = useState(false);

  const toggleComponent = () => {
    setShowLogin(!showLogin);
  };
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("theme")
  );

  useEffect(() => {
    themeChange(false);
    if (currentTheme === null) {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setCurrentTheme("dark");
      } else {
        setCurrentTheme("light");
      }
    }
    // 👆 false parameter is required for react project
  }, []);

  const { user, logOut } = useContext(AuthContext);

  // Function to clear localStorage
  const clearLocalStorage = () => {
    localStorage.clear();
  };

  const handleLogOut = async () => {
    try {
      clearLocalStorage(); // Clear localStorage before logout
      await logOut();
      toast.success("Successfully logged out!");
    } catch (err) {
      console.log(err);
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openModal = () => {
    setIsMenuOpen(true);
  };

  const closeModal = () => {
    setIsMenuOpen(false);
  };

  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);

  const toggleSearchField = () => {
    setIsSearchFieldVisible(!isSearchFieldVisible);
  };

  const searchIcon = isSearchFieldVisible ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
      />
    </svg>
  );

  
  const searchFieldAnimation = useSpring({
    transform: isSearchFieldVisible ? 'translateX(0%)' : 'translateX(40%)',
    opacity: isSearchFieldVisible ? 100 : 0,
  });


  const menuItems = (
    <React.Fragment>
      <li className="cursor-pointer font-bold md:text-lg nav hover:text-[#ED3A58]">
        {/* Search Icon */}
        <button
          className="btn btn-ghost btn-circle"
          onClick={toggleSearchField}
        >
          {searchIcon}
        </button>
      </li>

      {isSearchFieldVisible && (
        <div className="cursor-pointer font-bold md:text-lg ">
          {/* Search Field */}
          <animated.div className="search-field" style={searchFieldAnimation}>
      <SearchBar />
    </animated.div>
        </div>
      )}

      {!isSearchFieldVisible && (
        <>
          <Link to="/coupon-page">
            <li className="cursor-pointer font-bold md:text-lg nav hover:text-[#ED3A58]">
              Offer List
            </li>
          </Link>
          <Link to="/blog">
            <li className="cursor-pointer font-bold md:text-lg  nav hover:text-[#ED3A58]">
              Blog
            </li>
          </Link>

          <Link to="/claim-cash-back">
            <li className="cursor-pointer font-bold md:text-lg  nav hover:text-[#ED3A58]">
              Claim Cashback
            </li>
          </Link>
          <Link to="/whatsnew">
            <li className="cursor-pointer font-bold md:text-lg  nav hover:text-[#ED3A58]">
              Whats new
            </li>
          </Link>
          <li>
            <label className="swap">
              <input type="checkbox" />
              <SunIcon
                data-set-theme="light"
                data-act-class="ACTIVECLASS"
                className={
                  "fill-current w-6 h-6 " +
                  (currentTheme === "dark" ? "swap-on" : "swap-off")
                }
              />
              <MoonIcon
                data-set-theme="dark"
                data-act-class="ACTIVECLASS"
                className={
                  "fill-current w-6 h-6 " +
                  (currentTheme === "light" ? "swap-on" : "swap-off")
                }
              />
            </label>
          </li>
        </>
      )}

      {user?.uid ? (
        <>
          <div className="flex items-center dropdown">
            <div className="dropdown">
              <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                <div className="w-10 rounded-full">
                  <img src={person.photo ? `${IMG_BASE_URL}/users/${person.photo}` : (user.photoURL || dummyImage)} alt="User" />
                </div>
              </label>
              <ul
                tabIndex={0}
                className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-sm w-52"
              >
                <li>
                  <Link to="/dashboard" className="justify-between">
                    Dashboard
                    {/* <span className="badge">New</span> */}
                  </Link>
                </li>
                <li>
                  <a onClick={() => window.my_modal_2.showModal()}>Logout</a>
                </li>
                <li className="disabled">
                  <span className="text-[10px] text-base-content">
                    {location.country}
                  </span>
                </li>
              </ul>
            </div>
            <span className="ml-2 font-semibold" tabIndex={0}>
              {person?.name
                ? truncateName(person.name)
                : truncateName(user?.displayName)}{" "}
              <FontAwesomeIcon icon={faCaretDown} className="w-4 h-4 ml-1" />
            </span>
          </div>
        </>
      ) : (
        <button
          className="bg-[#ED3A58] px-4 text-md rounded-sm text-white"
          onClick={openModal}
        >
          Join Now
        </button>
      )}
      <Link
        to="/referral"
        className="bg-[#ED3A58] px-4 text-md rounded-sm text-white"
      >
        Refer & Earn
      </Link>
    </React.Fragment>
  );
  

  return (
    <div
      className="bg-base-200 w-full h-16 md:h-20 lg:h-24 xl:h-32"
      style={{ position: "sticky", top: 0, zIndex: 50 }}
    >
      <div>
        <div className="bg-transparent md:relative sticky md:top-0 z-50">
          <ToastContainer position="top-right" />
          <div className="drawer px-4 md:py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl z-50">
            <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content relative flex items-center lg:justify-evenly justify-between gap-2">
              <div className="flex-none lg:hidden">
                <label
                  htmlFor="my-drawer-3"
                  className="btn btn-square btn-ghost"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block w-6 h-6 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </label>
              </div>
              <div className="">
                <Link to="/" className="inline-flex items-center">
                  <img
                    className="w-[60%] h-[50%] lg:w-[80%] lg:h-[75%] md:h-[60%] md:w-[55%]"
                    src={logo}
                    alt=""
                  />
                </Link>
                <div className="hidden md:flex md:items-center">
                  <MdLocationOn className="text-[#207198] text-[10px] mt-[1px]" />
                  <p className="text-[8px] text-base-content">
                    {location.country}
                  </p>
                </div>
              </div>
              <ul className="cursor-pointer text-lg items-center hidden space-x-8 lg:flex text-blace style={{ zIndex: 10 }}">
                {menuItems}
              </ul>

              <div className="md:hidden">
                {user?.uid ? (
                  <>
                    <div className="flex items-center dropdown">
                      <div className="dropdown">
                        <label
                          tabIndex={0}
                          className="btn btn-ghost btn-circle avatar"
                        >
                          <div className="md:w-10 w-8 rounded-full">
                            <img src={user.photoURL || dummyImage} alt="User" />
                          </div>
                        </label>
                        <ul
                          tabIndex={0}
                          className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-sm w-52"
                        >
                          <li>
                            <Link to="/dashboard" className="justify-between">
                              Dashboard
                              {/* <span className="badge">New</span> */}
                            </Link>
                          </li>
                          <li>
                            <a onClick={() => window.my_modal_2.showModal()}>
                              Logout
                            </a>
                          </li>
                          {/* <li className="disabled">
                            <span className="text-[10px] text-base-content">
                              {location.country}, {location.city}
                            </span>
                          </li> */}
                        </ul>
                      </div>
                      <div
                        className="md:ml-2 ml-0 font-semibold md:text-sm text-sm flex justify-center items-center"
                        tabIndex={0}
                      >
                        <div>
                          {person?.name
                            ? truncateName(person.name)
                            : truncateName(user?.displayName)}{" "}
                        </div>
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className="md:w-4 md:h-4 md:ml-1 w-2 h-2 ml-1"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <Link
                    to="/signup"
                    className="bg-[#ED3A58] px-4 md:text-md rounded-sm text-white text-sm"
                  >
                    Join
                  </Link>
                )}
              </div>
            </div>
            <div className="drawer-side">
              <label htmlFor="my-drawer-3" className="drawer-overlay"></label>

              <ul className="menu p-4 w-80 h-full bg-base-200 gap-2">
                <li className="font-medium md:text-lg">
                  <div to="/location" className="flex items-center py-2">
                    <span className="mr-2">
                      <MdLocationOn />
                    </span>
                    <p className="text-xs font-medium">
                      {location.country}, {location.city}
                    </p>
                  </div>
                </li>
                <hr className="my-1 opacity-25" />

                <li className="cursor-pointer font-bold md:text-lg nav mt-5">
                  <Link to="/" className="flex items-center py-2">
                    <span className="mr-2">
                      <MdHome />
                    </span>
                    Home
                  </Link>
                </li>
                <hr className="my-1 opacity-0" />

                <li className="cursor-pointer font-bold md:text-lg nav">
                  <Link to="/coupon-page" className="flex items-center py-2">
                    <span className="mr-2">
                      <MdFormatListBulleted />
                    </span>
                    Offer List
                  </Link>
                </li>
                <hr className="my-1 opacity-0" />

                <li className="cursor-pointer font-bold md:text-lg nav">
                  <Link to="/blog" className="flex items-center py-2">
                    <span className="mr-2">
                      <MdArticle />
                    </span>
                    Blog
                  </Link>
                </li>
                <hr className="my-1 opacity-0" />

                <li className="cursor-pointer font-bold md:text-lg nav">
                  <Link
                    to="/claim-cash-back"
                    className="flex items-center py-2"
                  >
                    <span className="mr-2">
                      <MdAttachMoney />
                    </span>
                    Claim Cashback
                  </Link>
                </li>
                <hr className="my-1 opacity-0" />

                <li className="cursor-pointer font-bold md:text-lg nav">
                  <Link to="/whatsnew" className="flex items-center py-2">
                    <span className="mr-2">
                      <MdNewReleases />
                    </span>
                    What's New
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isMenuOpen}
        onRequestClose={closeModal}
        contentLabel="Signup Modal"
        className="ml-64 fixed inset-0 overflow-y-auto shadow-gray-300 shadow-lg z-50"
        overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-40 transition-opacity z-50"
      >
        <div
          data-aos="zoom-in"
          className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl mr-[20%]"
        >
          <div className="bg-white grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1  pl-4 pt-5 sm:pl-6 sm:pb-10 mx-auto">
            <div className="flex justify-center items-center">
              {/* Animation or other content */}
              <img src="./banner-pic.png" alt=""></img>
            </div>
            <div className="bg-white  drop-shadow-[0_35px_35px_rgba(0,0,0,0.08)]  py-8 lg:px-16 md:px-10 px-16">
              {showLogin ? <Login /> : <Signup setShowLogin={setShowLogin} />}
              {/* <h2 className="text-xs text-[#207198]">Forgot Password?</h2> */}
              <div className="flex justify-between items-center">
                <hr></hr>
                <h2>or</h2>
                <hr></hr>
              </div>
              <div className="flex flex-row justify-center items-center mt-6">
                <h2 className="text-sm text-[#207198]">
                  {showLogin
                    ? "Don't have an account?"
                    : "Already have an account?"}
                </h2>
                <button
                  className="text-sm text-[#ED3A58] ml-1.5 hover:underline cursor-pointer"
                  onClick={toggleComponent}
                >
                  {showLogin ? "Sign up" : "Login"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <dialog id="my_modal_2" className="modal max-w-sm md:max-w-full">
        <form method="dialog" className="modal-box">
          <h3 className="font-bold text-lg">
            Hello,{" "}
            {person?.name
              ? truncateName(person.name)
              : truncateName(user?.displayName)}{" "}
          </h3>
          <p className="py-4">Are you sure you want to log out?</p>
          <div className="modal-action">
            <button
              className="btn bg-[#ED3A58] hover:bg-red-700 text-white"
              onClick={handleLogOut}
            >
              Yes, I'm
            </button>
          </div>
        </form>

        <form method="dialog" className="modal-backdrop">
          <button>Close</button>
        </form>
      </dialog>
      <BottomNav></BottomNav>
      <div className="md:hidden mb-3">
        <SearchBar></SearchBar>
      </div>
    </div>
  );
};

export default Navber;
