import React, { useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { forgetPassword } = useContext(AuthContext);
  const [loginError, setLoginError] = useState("");
  const [success, setSuccess] = useState(false); // Add a success state
  const [loading, setLoading] = useState(false); // Add a loading state
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/dashboard";

  const handleLogin = (data) => {
    setLoginError("");
    setLoading(true);
    forgetPassword(data.email)
      .then((result) => {
        toast.success("Reset email has been sent!");
        setSuccess(true); // Set the success state to true
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        console.log(error.message);
        setLoginError(error.message);
        setLoading(false); // Stop loading
      });
  };

  return (
    <div className="min-h-screen p-4">
      <div className="bg-base-200 p-10 rounded shadow-lg max-w-3xl w-full">
        {success ? ( // Show success message if the email was sent successfully
          <p className="text-green-600 text-center font-bold mb-4">
            Reset email has been sent!
          </p>
        ) : (
          <>
            <h2 className="text-3xl text-center font-bold text-gray-800 mb-8">
              Reset Password
            </h2>
            <form onSubmit={handleSubmit(handleLogin)} className="space-y-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="w-full appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: "Email is required!" })}
                  placeholder="Enter your email"
                />
                 {errors.email && (
                  <span className="text-red-600 text-xs">
                    {errors.email?.message}
                  </span>
                )}
              </div>
              <button
                className="w-full bg-[#ED3A58] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                {loading ? "Loading..." : "Reset Password"} {/* Show "Loading..." while loading */}
              </button>
              <div>
                {loginError && <p className="text-xs text-red-600">{loginError}</p>}
              </div>
            </form>
          </>
        )}
      </div>
      <ToastContainer /> 
    </div>
  );
};

export default ResetPassword;
