import React from 'react';
import { Link } from 'react-router-dom';

const SingleCashBackCard = ({ product, productHandler }) => {
  const { image, price, name, url } = product;
  const truncatedName = name.split(' ').slice(0, 10).join(' ');
  const displayName = truncatedName.length < name.length ? truncatedName + '...' : truncatedName;

  return (
    <div>
      <Link to={url} target='_blank'>
        <div
          onClick={() => productHandler(product)}
          className='relative bg-gray-50 rounded-xl shadow-lg hover:shadow-[8px_9px_20px_10px_rgba(70,177,157,0.35)] py-6'
        >
          {/* Badge */}
          <span className='absolute custom-Badge top-0 right-4 inline-block bg-red-500 text-white text-md font-bold px-1'>
            DEAL
            {/* <h2 className='mt-3 text-xs'>{time} min </h2> */}
            <h2 className='text-center text-xs'>left</h2>
          </span>

          {/* Product Image */}
          <div className='flex justify-center items-start'>
            <img className='w-full h-[200px] object-contain' src={image} alt={displayName} />
          </div>

          {/* Product Details */}
          <div className='px-7'>
            <p className='text-[#0c0a0a] font-bold'>{displayName}</p>
            <h2 className='text-[#ED3A58] font-bold'>
              <span className='GradiantTextColour2'>{price}</span>
            </h2>
            {/* <h2 className='line-through text-[#A0A0A0] text-lg'>${oldPrice}</h2> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SingleCashBackCard;
