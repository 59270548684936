import React from 'react';
import Sidebar from '../Coupons/Sidebar';
import Coupon from '../Coupons/Coupons';
import MerchantwiseCoupons from './MerchantwiseCoupons';
import { useParams } from 'react-router-dom';

const Merchant = () => {
  const { name } = useParams();
    return (
        <div className="pb-10">
      <div className="flex flex-wrap">
        <div className="w-full md:w-9/12 p-1">
          {/* Left section content */}
          <MerchantwiseCoupons name={name}></MerchantwiseCoupons>
        </div>
        <div className="w-full md:w-3/12 pr-10">
          {/* Right section content */}
          <Sidebar></Sidebar>
          {/* <RecommendedStores></RecommendedStores> */}
        </div>
      </div>
    </div>
    );
};

export default Merchant;