

function DashboardStats({title, icon, value, description, colorIndex}){

    const COLORS = ["primary", "primary"]

    return(
        <div className="stats shadow w-30">
            <div className="stat">
                <div className={`stat-figure text-${COLORS[colorIndex%2]}`}>{icon}</div>
                <div className="text-sm">{title}</div>
                <div className={`text-xl font-black text-${COLORS[colorIndex%2]}`}>${value}</div>
                {/* <div className={"stat-desc  " + getDescStyle()}>{description}</div> */}
            </div>
        </div>
    )
}

export default DashboardStats