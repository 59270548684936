import React, { useState } from 'react';

const Disputes = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const disputesPerPage = 4;

  // Dummy dispute data
  const disputes = [
    { id: 1, subject: 'Unauthorized transaction', status: 'Open' },
    { id: 2, subject: 'Item not as described', status: 'Open' },
    { id: 3, subject: 'Billing discrepancy', status: 'Closed' },
    { id: 4, subject: 'Delivery delay', status: 'Open' },
    { id: 5, subject: 'Product quality issue', status: 'Open' },
    { id: 6, subject: 'Refund request', status: 'Closed' },
    { id: 7, subject: 'Shipping error', status: 'Open' },
    { id: 8, subject: 'Cancellation dispute', status: 'Open' },
    // Add more dispute objects as needed
  ];

  const indexOfLastDispute = currentPage * disputesPerPage;
  const indexOfFirstDispute = indexOfLastDispute - disputesPerPage;
  const currentDisputes = disputes.slice(indexOfFirstDispute, indexOfLastDispute);

  const totalPages = Math.ceil(disputes.length / disputesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="min-h-screen p-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Disputes</h1>
        {currentDisputes.length > 0 ? (
          <>
            <ul className="space-y-4">
              {currentDisputes.map((dispute) => (
                <li key={dispute.id} className="bg-base-200 rounded-lg shadow p-4 flex justify-between items-center text-left">
                  <div>
                    <h2 className="text-lg font-bold">{dispute.subject}</h2>
                    <p className="text-gray-700">Status: {dispute.status}</p>
                  </div>
                  <div className='ml-5'>
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mr-2"
                      onClick={() => console.log(`View dispute ${dispute.id}`)}
                    >
                      View
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                      onClick={() => console.log(`Resolve dispute ${dispute.id}`)}
                    >
                      Resolve
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  className={`${
                    i + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
                  } py-2 px-3 rounded mx-1`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="text-gray-700">No disputes found.</p>
        )}
      </div>
    </div>
  );
};

export default Disputes;
