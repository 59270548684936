import { useEffect, useState } from 'react';
import { FaTwitter, FaFacebook, FaInstagram, FaWhatsapp, FaFacebookMessenger } from 'react-icons/fa';
import { BASE_URL } from '../../../../api/api';
import axios from 'axios';

async function fetchProfileData(accessToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(`${BASE_URL}/profile`, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching profile data:', error);
    throw error; // Rethrow the error to handle it in the calling function
  }
}


function AmountStats() {
  const [person, setPerson] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    // Check if the user is logged in (access_token exists)
    if (accessToken) {
      fetchProfileData(accessToken)
        .then((data) => {
          setPerson(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching profile data:', error);
          setLoading(false);
        });
    } else {
      // User is not logged in, set loading to false
      setLoading(false);
    }
  }, []);

  console.log('Profile27 tarikh:', person);

  const [copiedItem, setCopiedItem] = useState(null);

  const copyItem = (item) => {
    navigator.clipboard
      .writeText(item)
      .then(() => setCopiedItem(item))
      .catch((error) => console.error('Error copying item:', error));
  };

  const resetCopyStatus = () => {
    setCopiedItem(null);
  };

  const referralLink = `https://markdowntoday.com/?ref=${person.affilate_code}`;
  const message =
    "Hi friends! Join Markdowntoday with my referral code or simply click this link to get a $10 joining bonus! By signing up using my code, you'll be eligible for exclusive offer deals and exciting bonuses.";
  const urlEncodedMessage = encodeURIComponent(message);

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?text=${urlEncodedMessage}&url=${encodeURIComponent(
      referralLink
    )}`;
    window.open(url, '_blank');
  };

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      referralLink
    )}&quote=${urlEncodedMessage}`;
    window.open(url, '_blank');
  };

  const shareOnInstagram = () => {
    alert('Instagram does not support direct URL sharing.');
  };

  const shareOnWhatsapp = () => {
    const url = `https://api.whatsapp.com/send?text=${urlEncodedMessage + ' ' + encodeURIComponent(
      referralLink
    )}`;
    window.open(url, '_blank');
  };

  const shareOnMessenger = () => {
    alert('Messenger requires a Facebook App to share a URL.');
  };

  return (
    <div className="stats bg-base-100 shadow mt-6 w-full max-w-6xl">
      {/* Show the loader while loading */}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="stat w-1/2">
            <div className="stat-title">Referral Code</div>
            {person.affilate_code ? (
              <div className="stat-value">{person.affilate_code}</div>
            ) : (
              <div className="text-base">You don't have a referral code currently!</div>
            )}
            <div className="stat-actions">
              {person.affilate_code && (
                <button
                  className="btn btn-xs"
                  onClick={() => copyItem(person.affilate_code)}
                  disabled={copiedItem === `${person.affilate_code}`}
                >
                  {copiedItem === `${person.affilate_code}` ? 'Copied' : 'Copy'}
                </button>
              )}
            </div>
          </div>

          <div className="stat">
            <div className="stat-title">Referral Link</div>
            {person.affilate_code ? (
              <>
                <div className="text-base">{referralLink}</div>
                <div className="stat-actions flex items-center gap-3">
                  <button
                    className="btn btn-xs"
                    onClick={() => copyItem(referralLink)}
                    disabled={copiedItem === referralLink}
                  >
                    {copiedItem === referralLink ? 'Copied' : 'Copy'}
                  </button>
                  <div className="social-icons flex gap-3">
                    <FaTwitter className='cursor-pointer' onClick={shareOnTwitter} />
                    <FaFacebook className='cursor-pointer' onClick={shareOnFacebook} />
                    <FaFacebookMessenger className='cursor-pointer' onClick={shareOnMessenger} />
                    <FaInstagram className='cursor-pointer' onClick={shareOnInstagram} />
                    <FaWhatsapp className='cursor-pointer' onClick={shareOnWhatsapp} />
                  </div>
                </div>
              </>
            ) : (
              // Show "You don't have a referral code currently!" message here
              <div className="text-base">You don't have a referral code currently!</div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AmountStats;

