
import Sidebar from "./Sidebar";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/api";
import {
  FaFacebook,
  FaTwitter,
  FaShareAlt,
  FaWhatsapp,
  FaPinterest,
  FaRegTimesCircle,
} from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
} from "react-share";
import { useSpring, animated } from "react-spring";
import { Link, useParams } from "react-router-dom";


const Coupon = () => {

    const { id } = useParams();

  const [couponData, setCouponData] = useState([]);
  const [copiedCouponCode, setCopiedCouponCode] = useState("");
  const [openShareButtons, setOpenShareButtons] = useState({});



  useEffect(() => {
    // Fetch coupon data from the API
    axios
      .get(`${BASE_URL}/single-coupon/${id}`)
      .then((response) => {
        const data = response.data;
        setCouponData(data);
      })
      .catch((error) => {
        console.error("Error fetching coupon data:", error);
      });
  }, [id]);

  console.log("Coupon Data paise:", couponData);



  // Function to copy the coupon code to the clipboard
  const copyCouponCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopiedCouponCode(code);
  };

  // Function to toggle share buttons visibility for a specific coupon
  const toggleShareButtons = (couponId) => {
    setOpenShareButtons((prevState) => ({
      ...prevState,
      [couponId]: !prevState[couponId],
    }));
  };

  const shareButtonsAnimation = useSpring({
    opacity: 1,
    maxHeight: "100px",
    overflow: "hidden",
  });
  return (
    <div className="pb-10">
      <div className="flex flex-wrap">
        <div className="w-full md:w-9/12 p-1">
          {/* Left section content */}
         
          <div
          key={couponData.id}
          className="bg-white rounded-lg shadow-md overflow-hidden mt-8 mb-8 mx-10"
        >
          <div className="bg-gradient-to-r from-red-500 to-orange-500 px-4 py-3 flex items-center justify-between">
            <div className="text-white font-bold text-base sm:text-lg">
              {couponData.type === 0 ? "P" : couponData.type === 1 ? "D" : ""}
            </div>
            <div className="text-white text-xs sm:text-sm">
              Expires: {couponData.end_date}
            </div>
            <div className="text-white text-xs sm:text-sm">
              Started From: {couponData.start_date}
            </div>

            <div className="flex items-center space-x-2">
              <button
                className="text-white hover:text-gray-200 p-2 rounded-full"
                style={{ backgroundColor: "#1091F3" }}
                onClick={() => toggleShareButtons(couponData.id)}
              >
                {openShareButtons[couponData.id] ? (
                  // FaRegTimesCircle icon for closing the share buttons
                  <FaRegTimesCircle size={16} />
                ) : (
                  // FaShareAlt icon for displaying the share buttons
                  <FaShareAlt size={16} />
                )}
              </button>
            </div>

            {/* Share Buttons */}
            {openShareButtons[couponData.id] && (
              <animated.div
                className="flex items-center space-x-2"
                style={shareButtonsAnimation}
              >
                {/* Facebook */}
                <FacebookShareButton
                  url={`at @${couponData.partner.name}. Plus earn ${couponData.price}% cash back. https://www.markdowntoday.com/coupon/${couponData.id} via @Markdowntoday`}
                  quote={`at @${couponData.partner.name}. Plus earn ${couponData.price}% cash back. https://www.markdowntoday.com/coupon/${couponData.id} via @Markdowntoday`}
                  hashtag="#YourHashtag"
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#1091F3" }}
                  >
                    <FaFacebook size={17} />
                  </button>
                </FacebookShareButton>

                {/* Twitter */}
                <TwitterShareButton
                  url={`at @${couponData.partner.name}. Plus earn ${couponData.price}% cash back. https://www.markdowntoday.com/coupon/${couponData.id} via @Markdowntoday`}
                  title={couponData.coupon_title}
                  // hashtags={["YourHashtag1", "YourHashtag2"]}
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#1da1f2" }}
                  >
                    <FaTwitter size={16} />
                  </button>
                </TwitterShareButton>

                {/* Whatsapp */}
                <WhatsappShareButton
                  url={`at @${couponData.partner.name}. Plus earn ${couponData.price}% cash back. https://www.markdowntoday.com/coupon/${couponData.id} via @Markdowntoday`}
                  title={couponData.coupon_title}
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#25d366" }}
                  >
                    <FaWhatsapp size={16} />
                  </button>
                </WhatsappShareButton>

                {/* Pinterest */}
                <PinterestShareButton
                  url={`at @${couponData.partner.name}. Plus earn ${couponData.price}% cash back. https://www.markdowntoday.com/coupon/${couponData.id} via @Markdowntoday`}
                  media={couponData.image_url}
                  description={couponData.coupon_title}
                >
                  <button
                    className="text-white hover:text-gray-200 p-2 rounded-full"
                    style={{ backgroundColor: "#bd081c" }}
                  >
                    <FaPinterest size={16} />
                  </button>
                </PinterestShareButton>
              </animated.div>
            )}
            {/* End Share Buttons */}
          </div>
          <div className="p-4 flex flex-col sm:flex-row justify-between">
            <div className="flex items-center mb-4">
              <img
                src={couponData.image_url}
                alt="Logo"
                className="h-8 sm:h-20 mr-4 sm:mr-7"
              />
              <div>
                <div className="text-sm sm:text-base font-bold mb-2">
                  {couponData.coupon_title}
                </div>
                <div className="text-gray-600 max-w-2xl text-xs sm:text-sm">
                  {couponData.description}
                </div>
              </div>
            </div>
            <button
              className="bg-red-500 hover:bg-orange-600 text-white btn rounded-lg shadow"
              onClick={() => copyCouponCode(couponData.code)}
            >
              {copiedCouponCode === couponData.code ? "Copied!" : "Get Coupon"}
            </button>
          </div>
          <div className="bg-gray-200 px-4 py-3 flex items-center justify-between text-green-600 font-semibold text-xs sm:text-sm">
            <div>
              Plus{" "}
              {couponData.type === "0"
                ? `${couponData.price}%`
                : couponData.type === "1"
                ? `$${couponData.price}`
                : ""}{" "}
              Cash Back With Markdowntoday
            </div>
            <div>
              <Link
                to="/referral"
                className="text-indigo-500 hover:text-indigo-600"
              >
                Refer & Earn
              </Link>
            </div>
          </div>
        </div>




        </div>
        <div className="w-full md:w-3/12 pr-10">
          {/* Right section content */}
          <Sidebar></Sidebar>
          {/* <RecommendedStores></RecommendedStores> */}
        </div>
      </div>
    </div>
  );
};

export default Coupon;
