import React from "react";
import androidMoc from "../../../Assets/android-moc.png";
import iosMoc from "../../../Assets/ios-moc.png";
import androidDownloadButton from "../../../Assets/AppDownloadButtons/androidButton.png";
import iosDownloadButton from "../../../Assets/AppDownloadButtons/iosButton.png";
import "./AppDownload.css"; // Import CSS file for animation styles
import androidLogo from "../../../Assets/androidicon.png";
import iosLogo from "../../../Assets/ios.png";
const AppDownload = () => {
  return (
    // <div className="grid grid-cols-1 md:grid-cols-2 gap-6 justify-center px-4 md:px-6 lg:px-10 my-9">
    //   {/* Android App */}
    //   <div className="flex items-center bg-base-200 rounded-lg shadow-md p-6 hover:bg-base-300">
    //     <div className="mr-6">
    //       <div className="relative w-16 h-16">
    //         <img
    //           src={androidMoc}
    //           alt="Android Logo"
    //           className="absolute w-full h-full object-contain android-logo"
    //         />
    //       </div>
    //     </div>
    //     <div>
    //       <h2 className="text-3xl font-semibold text-blue-500 mb-2">Download Our Android App</h2>
    //       <p className="text-base text-gray-600 mb-4">
    //         Experience our feature-rich Android app with advanced functionality designed to enhance your mobile experience. Stay connected, access exclusive content, and enjoy seamless navigation. Download now and explore a world of possibilities!
    //       </p>
    //       <a
    //         href="https://example.com/android-app-download"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="inline-block rounded-lg overflow-hidden focus:outline-none"
    //       >
    //         <img
    //           src={androidDownloadButton}
    //           alt="Download Android App"
    //           className="w-32 h-auto transform hover:scale-105 transition-transform duration-200"
    //         />
    //       </a>
    //     </div>
    //   </div>

    //   {/* iOS App */}
    //   <div className="flex items-center bg-base-200 rounded-lg shadow-md p-6 hover:bg-base-300">
    //     <div className="mr-6">
    //       <div className="relative w-16 h-16">
    //         <img
    //           src={iosMoc}
    //           alt="iOS Logo"
    //           className="absolute w-full h-full object-contain ios-logo"
    //         />
    //       </div>
    //     </div>
    //     <div>
    //       <h2 className="text-3xl font-semibold text-blue-500 mb-2">Download Our iOS App (Be Available Soon)</h2>
    //       <p className="text-base text-gray-600 mb-4">
    //         Take advantage of our innovative iOS app that brings you unparalleled functionality and a seamless user experience. Discover a world of convenience at your fingertips. Get access to exclusive features, personalized content, and more. Download now and embark on an extraordinary journey!
    //       </p>
    //       <a
    //         href="https://example.com/ios-app-download"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="inline-block rounded-lg overflow-hidden focus:outline-none"
    //       >
    //         <img
    //           src={iosDownloadButton}
    //           alt="Download iOS App"
    //           className="w-32 h-auto transform hover:scale-105 transition-transform duration-200"
    //         />
    //       </a>
    //     </div>
    //   </div>
    // </div>
    <div className="p-1">
      <div className="md:flex md:items-center hidden">
        <div className="px-10 mx-auto container align-middle">
          <div className="grid grid-cols-2 gap-2">
            <div className="shadow rounded-lg py-3 px-5 bg-base-200">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h4 className="text-2xl font-bold text-left">
                    Download Our Android App
                  </h4>
                  <h6 className="text-base">
                    {" "}
                    Experience our feature-rich Android app with advanced
                    functionality designed to enhance your mobile experience
                  </h6>
                </div>
                <div style={{ maxHeight: "200px" }}>
                  <img
                    src={androidLogo}
                    alt="Android Logo"
                    className="min-w-full h-full"
                  />
                </div>
              </div>
              <div className="transform hover:scale-105 transition-transform duration-200">
                <a
                  href="https://play.google.com/store/apps/details?id=carociba.markdowntoday&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block rounded-lg overflow-hidden focus:outline-none"
                >
                  <img
                    src={androidDownloadButton}
                    alt="Download iOS App"
                    className="w-32 h-auto"
                  />
                </a>
              </div>
            </div>
            <div className="shadow rounded-lg py-3 px-5 bg-base-200">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h4 className="text-2xl font-bold text-left">
                    Download Our iOS App (Be Available Soon)
                  </h4>
                  <h6 className="text-base">
                    Take advantage of our innovative iOS app that brings you
                    unparalleled functionality and a seamless user experience.
                  </h6>
                </div>
                <div style={{ maxHeight: "200px" }}>
                  <img
                    src={iosLogo}
                    alt="Android Logo"
                    className="min-w-full h-full"
                  />
                </div>
              </div>
              <div className="transform hover:scale-105 transition-transform duration-200">
                <a
                  href="https://example.com/ios-app-download"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block rounded-lg overflow-hidden focus:outline-none"
                >
                  <img
                    src={iosDownloadButton}
                    alt="Download iOS App"
                    className="w-32 h-auto"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="card card-side bg-base-100 shadow-xl">
          <figure>
            <img src={androidMoc} alt="Movie" />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Download Our Android App</h2>
            <p>
              Experience our Android app with advanced functionality designed.
              Stay connected, access exclusive content, and enjoy seamless
              navigation.
            </p>
            <a
              href="https://example.com/android-app-download"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block rounded-lg overflow-hidden focus:outline-none"
            >
              <img
                src={androidDownloadButton}
                alt="Download Android App"
                className="w-32 h-auto transform hover:scale-105 transition-transform duration-200"
              />
            </a>
          </div>
        </div>
        <div className="card card-side bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">
              Download Our iOS App (Be Available Soon)
            </h2>
            <p>
              Take advantage of our innovative iOS app that brings you
              unparalleled functionality and a seamless user experience
            </p>
            <a
              href="https://example.com/android-app-download"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block rounded-lg overflow-hidden focus:outline-none"
            >
              <img
                src={iosDownloadButton}
                alt="Download Android App"
                className="w-32 h-auto transform hover:scale-105 transition-transform duration-200"
              />
            </a>
          </div>
          <figure>
            <img src={iosMoc} alt="Movie" />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
