import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, IMG_BASE_URL } from '../../../../api/api';
import { Link } from 'react-router-dom';
import dummyImage from '../../../../Assets/profile.png';

const ReferedPerson = () => {
  const [person, setPerson] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(`${BASE_URL}/affiliation`, config);
        setPerson(response.data);
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  console.log('Person paise:', person);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = person.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(person.length / itemsPerPage);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto mt-6">
      <h1 className="text-xl font-bold mb-4">Last Refered Person</h1>
      {person.length === 0 ? (
        <p>It seems that you haven't referred anybody yet!</p>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 max-w-5xl">
            {currentItems.map(man => (
              <Link to={`/cash-back-landing/${man.name}`} target="_blank" key={man.id}>
                <div key={man.id} className="bg-base-200 rounded shadow p-4">
                  <img
                    src={`${IMG_BASE_URL}/users/${man.photo}`}
                    alt={man.name}
                    onError={event => (event.target.src = dummyImage)}
                    className="w-30 h-30 object-cover rounded-full"
                  />
                  <h2 className="text-lg font-bold text-center">{man.name}</h2>
                </div>
              </Link>
            ))}
          </div>
          {/* Pagination */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`mx-2 px-3 py-1 rounded ${
                  currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReferedPerson;
