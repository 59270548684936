import React from "react";

const CookiePolicy = () => {
    document.title = "Privacy Policy";
    return (
        <div className="px-6 py-2 md:px-40 md:py-10">
            <div className="text-center">
                <h1 className="font-bold text-2xl mb-10">
                COOKIE POLICY
                    <hr/>
                </h1>
            </div>
            <p className="mb-3">Internet cookies are small text files that hold pieces of site data. That data could include your username
            and password for different websites or everything you left in your shopping cart when you closed your
            browser.</p>
            <p className="mb-3"> <a href="https://allaboutcookies.org/what-is-a-cookie" target="_blank" className="link text-blue-500">Browser cookies</a> can be helpful because they keep you from having to enter the same data each time
            you visit a website. But cookies may also cause some frustrations since many ecommerce sites and
            social media platforms use third-party cookies as trackers so they can show you ads based on your
            internet browsing habits.</p>
            <p className="mb-3">Aside from these privacy concerns, cookies themselves aren’t dangerous. The problem is some
            cybercriminals hijack cookies to gain access to your browsing sessions. The easiest way to stay in control
            of your online security is to know how to disable cookies and enable them in your web browser. Keep
            reading our guide to learn how and when to adjust your cookie settings.</p>

            
            <p className="font-bold mb-3">How to turn browser cookies on or off</p>
            
            <p className="mb-3">The process for enabling or disabling cookies may vary, depending on the web browser you use. We’ve
            provided information on the most popular platforms so that you can learn how to update your privacy
            settings no matter what browser you have.</p>
            

            <p className="font-bold mb-3">Google Chrome</p>
            
            <p className="mb-3"> <a href="https://allaboutcookies.org/how-to-clear-cookies-chrome" target="_blank" className="link text-blue-500">Managing your Chrome cookies</a> is fairly straightforward unless you have an iOS device. Here’s how to
            enable and disable your browser cookies, plus a workaround for managing cookies on your iPad or
            iPhone.</p>
            
            
            <p className="font-bold mb-3">Desktop</p>
            
            <p className="mb-3">You can access your settings in Google Chrome by going to the drop-down menu under the vertical dots
            in the upper-right corner of the screen. Then follow these steps:</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>From the drop-down menu, click Settings.</li>
                <li>Click the Privacy and security tab.</li>
                <li>Select Cookies and other site data, then look for the General settings section. It should be near
                the top.</li>
                <li>Choose the cookie settings you want:
                    <ul className="text-left">
                        <li><b>Allow all cookies</b> enables all cookies, including third-party trackers, in Chrome.</li>
                        <li><b>Block third-party cookies in Incognito </b>allows all cookies when you use Chrome regularly, but
                        blocks third-party trackers if you open an Incognito window.</li>
                        <li><b>Block third-party cookies </b>only blocks potential tracking cookies. Blocking these could cause
                        some sites to not load properly.</li>
                        <li><b>Block all cookies </b>doesn’t allow cookies of any kind while you use Chrome. The browser doesn’t
                        recommend this setting since disabling cookies can cause most sites to not load properly.</li>
                    </ul>
                </li>
            </ul>
            
            
            <p className="font-bold mb-3">Android</p>
            
            <p className="mb-3">The steps for enabling or disabling cookies in Chrome on your Android device are similar to the desktop
            version.</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>Tap the three vertical dots in the top right of the browser window, then choose Settings.</li>
                <li>Tap Site Settings and choose Cookies.</li>
                <li>Here you can turn your cookies on or off.</li>
            </ul>
            
            
            <p className="font-bold mb-3">iPhone and iPad</p>
            
            <p className="mb-3">Unfortunately, Google Chrome for iOS devices don’t allow you to modify your cookie settings, and the
            browser automatically enables cookies. You can, however, clear your cookies by doing the following:</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>Open Chrome and tap the three horizontal dots in the bottom-right corner of your screen.</li>
                <li>Scroll to the right and find the Settings option.</li>
                <li>Tap Privacy and Security, then Clear Browsing Data.</li>
                <li>Choose the items you want to clear, such as Cookies, Site Data, and set the time range.</li>
                <li>Tap Clear Browsing Data at the bottom of your screen.</li>
            </ul>
            
            
            <p className="font-bold mb-3">Microsoft Edge</p>
            
            <p className="mb-3">To enable or disable cookies in Microsoft Edge, follow these steps:</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>In your browser window, open Settings and More, then Settings.</li>
                <li>Choose Site Permissions, then Cookies and Site Data.</li>
                <li>Click See All Cookies and Site Data. Select the arrow for See All Cookies and Site Data to see all the
                cookies stored on your device.</li>
            </ul>
            
            <p className="mb-3">Keep your device clutter-free and learn <a href="https://allaboutcookies.org/how-to-clear-cookies-microsoft-edge" target="_blank" className="link text-blue-500">how to clear cookies in Microsoft Edge</a>.</p>
            
            
            <p className="font-bold mb-3">Mozilla Firefox</p>
            
            <p className="mb-3">Want to turn cookies on or off in Mozilla Firefox? Follow these steps:</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>From the Firefox menu, go to Preferences, then select Privacy &amp; Security.</li>
                <li>You’ll see Enhanced Tracking Protection, and further down the page, you’ll find Cookies and Site Data.
                These are the locations for cookies and their settings.</li>
                <li>You can make changes and manage cookies from both settings.</li>
            </ul>
            
            <p className="mb-3">Take control of your online privacy — learn <a href="https://allaboutcookies.org/how-to-clear-cookies-microsoft-edge" target="_blank" className="link text-blue-500">how to clear your Firefox cookies</a>.</p>

            
            

            
            <p className="mb-3 font-bold">Apple Safari</p>

            <p className="mb-3">Learning how to disable or enable cookies on Safari is pretty simple:</p>

            
            <p className="mb-3 font-bold">Desktop</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>Open Safari, then click Safari in the top navigation bar and choose Preferences.</li>
                <li>Select the Privacy tab.</li>
                <li>Turn on Prevent Cross-site Tracking to block third-party cookies.</li>
                <li>If you want to completely disable Safari cookies, add a checkmark next to Block All Cookies.</li>
            </ul>

            
            <p className="mb-3 font-bold">iPhone or iPad</p>
            <p className="mb-3">If you’re using an iOS device, you’ll find cookies under your iPhone’s or iPad’s Settings.</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>Scroll down and tap Safari in the Settings menu.</li>
                <li>Scroll down to the Privacy &amp; Security section.</li>
                <li>Toggle Prevent Cross-Site Tracking to block third-party cookies.</li>
                <li>To disable all Safari cookies, toggle the Block All Cookies setting on.</li>
            </ul>
            <p className="mb-3">It’s a good idea to also delete your Safari cookies on a regular basis. Check out our guide on <a href="https://allaboutcookies.org/how-to-clear-cookies-safari" target="_blank" className="link text-blue-500">how to clear
            cookies in Safari</a> to find out how.</p>

            
            
            
            <p className="font-bold mb-3">Opera</p>
            
            <p className="mb-3">Knowing how to turn cookies on or off in Opera gives you the ability to manage site referrals, redirects,
            and delete cookies at the end of each visit. Here’s how to turn your Opera cookies on and off:</p>

            
            <ul className="md:ml-20 text-left mb-3 list-decimal">
                <li>Open Opera and click the three horizontal bars in the top right corner to open the Easy Setup
                menu.</li>
                <li>Scroll down and click Go to Full Browser Settings.</li>
                <li>Click the Privacy &amp; Security tab, then click Cookies and Other Site Data.</li>
                <li>Choose which cookie settings you want to use:
                    <ul className="text-left">
                        <li><b>Allow all cookies</b> enables all cookies in Opera, including third-party cookies.</li>
                        <li><b>Block third-party cookies in private mode </b>enables all cookies when you use Opera regularly and
                        blocks third-party cookies if you browse in private mode.</li>
                        <li><b>Block third-party cookies </b>blocks third-party cookies that might track you across other sites, but
                        allows first-party cookies that belong to the site you’re currently visiting.</li>
                        <li><b>Block all cookies </b>disables all cookies. Opera doesn’t recommend this setting because most sites
                        need cookies to load properly.</li>
                    </ul>
                </li>
            </ul>
            <p className="mb-3">If you don’t want to delete previously installed cookies,<a href="https://allaboutcookies.org/how-to-clear-cookies-opera" target="_blank" className="link text-blue-500"> Opera allows you to clear cookies </a>whenever you
            close the browser. to find out how.</p>


            <p className="mb-3 font-bold">When should you disable cookies?</p>
            <p className="mb-3">If you want to have more privacy, you should know how to disable cookies anytime you want. For
            example, if you share a device with others, the person who uses the machine after you could see your

            internet activity. Knowing how to <a href="https://allaboutcookies.org/how-to-be-anonymous-online" target="_blank" className="link text-blue-500">browse online anonymously</a> also helps to mitigate the chances of others seeing your browsing traffic.</p>
            <p className="mb-3">Browser cookies may pose a security threat because hackers can hijack them to access your browser
            sessions. If the criminals get access, they can steal your personal information. They can use that to drain
            your bank accounts and steal your identity. Knowing how to change cookie settings will allow you to
            tweak internet cookies anytime.</p>
            <p className="mb-3">Sometimes too many cookies can cause your device to get bogged down and run slow. Since the pages
            you browse get saved to your hard drive, you could accumulate tons of cookies over time, causing your
            computer to be slow. Learning how to disable cookies will cause less storage consumption, possibly
            increasing device performance.</p>
            <p className="mb-3">You can <a href="https://allaboutcookies.org/how-to-clear-cookies" className="link text-blue-500" target="_blank">delete browser cookies</a> and get fewer targeted ads from online shopping sites. To delete
            browser cookies, a few quick steps is all it takes to lower the risks of a privacy breach.</p>


            <p className="mb-3 font-bold">When should you enable cookies?</p>
            <p className="mb-3">Cookies get a bad reputation because of security issues, but there are times when you should know how
            to enable cookies.</p>
            <p className="mb-3">When you <a href="https://allaboutcookies.org/information-in-cookies" className="link text-blue-500" target="_blank">accept cookies</a>, you may have a better experience while you browse the internet. Online
            shopping sites use cookies to remember the items you saved in your shopping cart. Other sites use cookies to remember your login info — you probably don’t want to waste time logging in to the same websites you visit every day.</p>
            <p className="mb-3">You may also want to know how to turn on cookies for accessing specific websites. Some sites won’t let you view their contents without accepting cookies.</p>


            <p className="mb-3 font-bold">Other ways to block cookies and trackers</p>
            <p className="mb-3">If you’re tired of cookie consent banners and opting-out of tracking, you may want to use one or all of these options.</p>
            <p className="mb-3">Cookie popups may seem like constant intrusions while you’re trying to work or shop online. To reduce the frequency of these annoying banners, you can use options like browser extensions, secure browsers, and a VPN.</p>


            <p className="mb-3 font-bold">Browser extensions</p>
            <p className="mb-3">Browser extensions can hide your online activities and keep your data safe. These can also block malicious websites and ads as well as provide anti-phishing technology for better security. Some privacy focused browser extensions include:</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li><a href="https://allaboutcookies.org/ghostery-review" className="link text-blue-500" target="_blank">Ghostery</a></li>
                <li><a href="https://privacybadger.org/" className="link text-blue-500" target="_blank">Privacy Badger</a></li>
                <li><a href="https://www.avast.com/en-us/avast-online-security#pc" className="link text-blue-500" target="_blank">Avast Online Security &amp; Privacy</a></li>
            </ul>


            <p className="mb-3 font-bold">Secure browsers</p>
            <p className="mb-3">Secure browsers are helpful because they block trackers and reduce intrusive pop-up ads. They may also provide incognito windows so you can search in private mode. If you’re looking for a secure browser, a few examples include:</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li><a href="https://brave.com/" className="link text-blue-500" target="_blank">Brave</a></li>
                <li><a href="https://www.mozilla.org/en-US/firefox/features/safebrowser/" className="link text-blue-500" target="_blank">Firefox</a></li>
                <li><a href="https://www.microsoft.com/en-us/edge?form=MA13FJ&exp=e00" className="link text-blue-500" target="_blank">Microsoft Edge</a></li>
            </ul>


            <p className="mb-3 font-bold">Virtual private networks (VPNs)</p>
            <p className="mb-3">If you want to have next-level cyber protection, you may want to use a VPN. A <a href="https://allaboutcookies.org/what-is-a-vpn" className="link text-blue-500">virtual private network</a> helps establish a secure connection when using the internet. All your data gets routed through an encrypted tunnel, which disguises your IP address. That helps guard against cyber-attacks.</p>
            <p className="mb-3">Some of our most recommended VPNs include:</p>
            
            <ul className="list-disc md:ml-20 text-left mb-3">
                <li><a href="https://allaboutcookies.org/nordvpn-review" className="link text-blue-500" target="_blank">NordVPN</a></li>
                <li><a href="https://allaboutcookies.org/surfshark-vpn-review" className="link text-blue-500" target="_blank">Surfshark</a></li>
                <li><a href="https://allaboutcookies.org/expressvpn-review" className="link text-blue-500" target="_blank">ExpressVPN Edge</a></li>
            </ul>



        </div>
    );
}

export default CookiePolicy;