import React from "react";


const AdvertisementDisclosure = () => {
    document.title = "Advertisement Disclosure";
    return (
        <div className="px-40 py-10">
            <div className="text-center">
                <h1 className="font-bold text-2xl mb-10">
                        ADVERTISEMENT DISCLOSURE
                    <hr/>
                </h1>
            </div>
            <p className="mb-3">We at Markdowntoday.com are proud to operate a cash back shopping rewards program for our members.  This means that when you click our links to participating stores, we may be paid a commission for your purchase.  We in turn share that commission with you in the form of cash back rewards.</p>
            <p className="mb-3">Be sure to check the exact terms and rules applicable for each store before you shop, as we don’t earn a commission and therefore cannot offer cash back rewards for some stores or for every product or product category at some stores.  The cash back rewards amount for each store and for specific products and product categories within each store are  subject to change at any time.</p>
            <p className="mb-3">From time to time, we work with participating stores to offer members increased cash back rewards.  An increased cash back rewards percentage is often promoted alongside a “was %” or a “crossed-out” cash back rewards amount which was a prior cash back rewards percentage for that store, but not necessarily or always the most recent or most common cash back rewards amount for that store.</p>
            <p className="mb-3">Likewise, when you engage in any other activities offered through our rewards programs, such as taking a survey, watching videos, or discovering great offers, our client may pay us for that activity, and we then share that payment with you as rewards.</p>
        </div>
    )
}


export default AdvertisementDisclosure;