import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, IMG_BASE_URL } from "../../../api/api";
import DOMPurify from "dompurify";

const SingleTopDeal = () => {
  const { id } = useParams();
  const [deal, setDeal] = useState({});

  useEffect(() => {
    fetch(`${BASE_URL}/single-deal/${id}`)
      .then((response) => response.json())
      .then((data) => setDeal(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="py-12 px-4 max-w-xl mx-auto sm:max-w-2xl md:max-w-full lg:px-8">
      <div className="flex flex-col justify-between lg:flex-row">
        <img
          src={`${IMG_BASE_URL}/products/${deal.photo}`}
          alt={deal.name}
          className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:w-1/2 lg:h-auto"
        />
        <div className="mt-6 lg:mt-0 lg:ml-8 w-1/2 px-4">
          <h1 className="text-xl font-semibold">{deal.name}</h1>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(deal.details),
            }}
          />
          <h2 className="mt-2 font-semibold">Price: ${deal.price}</h2>

          <Link to={`/cash-back-landing-products/${deal.id}`}>
            <button className="mt-4 px-4 py-2 bg-red-700 hover:bg-red-800 rounded-sm text-white max-w-max shadow-sm hover:shadow-lg">
              Get The Deal
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SingleTopDeal;
