import React from 'react';

const CashBackWork = () => {
  return (
    <div className="bg-base-200 rounded shadow p-4 text-left">
      <h2 className="text-2xl font-bold mb-4">How Does Our Cash Back Work?</h2>

      <div className="mb-4 text-left">
        <h3 className="text-lg font-bold mb-2">Step 1: Sign Up or Log In</h3>
        <p className="text-gray-600">
          To start earning cash back, follow these steps:
        </p>
        <ol className="list-decimal ml-6 mt-2 text-left">
          <li>If you're new, sign up for an account. If you're an existing user, log in to your account.</li>
          <li>Make sure you're signed in before proceeding to the next steps.</li>
        </ol>
      </div>

      <div className="mb-4 text-left">
        <h3 className="text-lg font-bold mb-2">Step 2: Browse Partner Stores</h3>
        <p className="text-gray-600">
          Discover our partner stores and brands where you can earn cash back on your purchases:
        </p>
        <ul className="list-disc ml-6 mt-2 text-left">
          <li>Explore the list of partner stores on our website or mobile app.</li>
          <li>Find your favorite stores or search for specific products.</li>
        </ul>
      </div>

      <div className="mb-4 text-left">
        <h3 className="text-lg font-bold mb-2">Step 3: Click and Shop</h3>
        <p className="text-gray-600">
          Here's how to earn cash back while shopping at our partner stores:
        </p>
        <ol className="list-decimal ml-6 mt-2 text-left">
          <li>Click on a store or offer to visit the partner website.</li>
          <li>Complete your purchase on the partner website without closing the browser tab or window.</li>
        </ol>
      </div>

      <div className="mb-4 text-left">
        <h3 className="text-lg font-bold mb-2">Step 4: Get Cash Back</h3>
        <p className="text-gray-600">
          Once your purchase is confirmed, you'll receive cash back in the following ways:
        </p>
        <ul className="list-disc ml-6 mt-2 text-left">
          <li>Check your account dashboard for cash back notifications.</li>
          <li>Receive email notifications with details of your cash back earnings.</li>
          {/* Add more ways to receive cash back if applicable */}
        </ul>
      </div>
    </div>
  );
};

export default CashBackWork;
